/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";
import { paginateArray, sortCompare } from "@core/utils/filter";
 
class WithdrawalService {
  getWithdrawalLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      phone: queryParams.phone,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      stat: queryParams.stat,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
      tag_id: queryParams.tags,
      bank_id: queryParams.bankId,
      bank_account: queryParams.bank_account_code,
    };
    return instance
      .get("/api/Withdrawal/index", { headers: authHeader(), params: pars })
      .then((response) => {
        // console.log(1111,response);
        // const {
        //   q = "",
        //   perPage = 10,
        //   page = 1,
        //   sortBy = "id",
        //   sortDesc = false,
        //   stat = 1,
        // } = queryParams;
        // const queryLowered = q.toLowerCase();
        // const filteredData = response.data.data.filter(
        //   (withdrawal) =>
        //     (withdrawal.user.phone.includes(queryLowered) ||
        //       withdrawal.user.username.toLowerCase().includes(queryLowered) ||
        //       withdrawal.user.email.toLowerCase().includes(queryLowered)) &&
        //     withdrawal.status === (stat || withdrawal.status)
        // );
        // const sortedData = filteredData.sort(sortCompare(sortBy));
        // if (sortDesc) sortedData.reverse();

        // const datas = {
        //   withdrawals: queryLowered
        //     ? paginateArray(sortedData, perPage, page)
        //     : response.data.data,
        //   total: queryLowered ? filteredData.length : response.data.count,
        //   pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        // };
        // console.log(1111,datas);
        const datas = {
          withdrawals: response.data.data,
          money: response.data.total,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;

      });
  }

  updateWithdrawal(withdrawalData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("withdrawalId", withdrawalData.withdrawalId);
    data.append("status", withdrawalData.status);
    data.append("rejectReason", withdrawalData.rejectReason);

    return instance.post("/api/Withdrawal/update-status", data, {
      headers: authHeader(),
    });
  }

  exportWithdrawals(queryParams) {
    const pars = {
      username: queryParams.username,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      stat: queryParams.stat,
      // pageNumber: queryParams.page,
    };
    const data = {};
    return instance.post("/api/Withdrawal/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }
}

export default new WithdrawalService();

<template>
  <b-card no-body class="border">
    <b-card-body>
      <b-row>
        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("Transaction id") }}</label>
          <b-form-input
            v-model="filter.reference"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="2">
          <b-form-group :label="$t('From')" label-for="fromDate">
            <flat-pickr
              id="fromDate"
              v-model="filter.fromdate"
              class="form-control"
              placeholder="YYYY-MM-DD HH:II:SS"
              :config="flatpickrConfig"
            />
            <!-- dateFormat= "Y-m-d H:i" -->
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label="$t('To')" label-for="toDate">
            <flat-pickr
              id="toDate"
              v-model="filter.todate"
              class="form-control"
              placeholder="YYYY-MM-DD HH:II:SS"
              :config="flatpickrConfig"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>{{ $t("Status") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.status"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
          <!-- :selectable="(item) => item.value !== filter.status" -->
          <!-- @input="(val) => $emit('update:statusFilter', val)" -->
        </b-col>
        <!-- action -->
        <b-col cols="12" md="4" class="d-flex align-items-end justify-content-start" style="padding-bottom: 20px">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormGroup,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: {
        reference: this.referenceFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        status: this.statusFilter,
      },
      flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S", // Set your desired format here
        enableTime: true, // Enable time selection
        time_24hr: true, // Use 24-hour format for time
      },
    };
  },
  methods: {
    onContextFromDate(ctx) {
      // this.$emit('update:fromdateFilter', ctx.selectedYMD)
      this.filter.fromdate = ctx.selectedYMD;
    },
    onContextToDate(ctx) {
      // this.$emit('update:todateFilter', ctx.selectedYMD)
      this.filter.todate = ctx.selectedYMD;
    },
    resetFilter() {
      this.$emit("update:referenceFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:statusFilter", null);
      this.filter = {
        reference: null,
        fromdate: null,
        todate: null,
      };
      this.$emit("refetch-data");
    },
    searchFilter() {
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("refetch-data");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <div v-if="isAddNewLocalDepositSidebarActive">
      <local-deposit-list-add-new
        :is-add-new-local-deposit-sidebar-active.sync="
          isAddNewLocalDepositSidebarActive
        "
        :transfer-mode-options="transferModeOptions"
        :company-bank-accounts="companyBankAccounts"
        :member-bank-accounts="memberBankAccounts"
        :user-data="userData"
        @refetch-data="refetchData"
      />
    </div>
    <!-- Filters -->
    <deposits-list-filters
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col v-if="$can('create', 'localdeposit')" cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="showModalAddNewDeposit()"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusCircleIcon" />
                  {{ $t("Create") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div style="margin: 8px 0px; color: white">
        Tổng tiền: {{ numberFormat(parseFloat(totalMoney) / 1000) }}
      </div>

      <b-table
        ref="refDepositListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchDeposits"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ref)="data">
          <b-link @click="showDepositDetail(data.item)">
            {{ data.item.ref }}
            <feather-icon
              v-if="data.item.local_deposit_attachements.length > 0"
              icon="PaperclipIcon"
            />
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          {{ data.item.user.username }}
        </template>

        <template #cell(type)="data">
          <div v-if="data.item.txn_type && data.item.txn_type.type">
            {{ data.item.txn_type.type.name }}
          </div>
        </template>

        <template #cell(Mode)="data">
          <div v-if="data.item.txn_type">
            {{ data.item.txn_type.name }}
          </div>
        </template>

        <template #cell(currency)="data">
          {{ data.item.currency }}
        </template>

        <template #cell(netAmount)="data">
          <span class="text-primary">{{
            numberFormat(
              (parseFloat(data.item.amount) - parseFloat(data.item.fee)) / 1000
            )
          }}</span>
        </template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(parseFloat(data.item.amount) / 1000)
          }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />{{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDeposits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Detail Modal -->
    <deposit-detail-modal :deposit-data.sync="depositData" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText, numberFormat, formatDateTime } from "@core/utils/filter";
import store from "@/store";
import depositStoreModule from "@/views/payments/deposit/depositStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
import localBankAccountStoreModule from "@/views/payments/local-bank-account/localBankAccountStoreModule";
import paymentAccountStoreModule from "@/views/payments/bank-account/bankAccountStoreModule";
import router from "@/router";
import localDepositStoreModule from "@/views/payments/local-deposit/localDepositStoreModule";
import LocalDepositListAddNew from "./deposit/LocalDepositListAddNew.vue";
import useDepositsList from "./deposit/useDepositsList";
import DepositsListFilters from "./deposit/DepositsListFilters.vue";
import userStoreModule from "../userStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";

export default {
  components: {
    DepositsListFilters,
    LocalDepositListAddNew,
    DepositDetailModal: () =>
      import("@/views/payments/deposit/DepositDetailModal.vue"),
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: [],
    }
  },
  data() {
    return {
      depositData: null,
      companyBankAccounts: [],
      memberBankAccounts: [],
      isAddNewLocalDepositSidebarActive: false
    };
  },
  methods: {
    async showModalAddNewDeposit() {
      await store
          .dispatch("payment-local-bank-account/fetchLocalBankAccounts", {
            perPage: 50,
            page: 1,
          })
          .then((response) => {
            const {localBankAccounts} = response;
            this.companyBankAccounts = localBankAccounts;
          });
      await store
          .dispatch("payment-bank-account/fetchBankAccounts", {
            userId: router.currentRoute.params.id,
            perPage: 50,
            page: 1,
          })
          .then((response) => {
            const {bankAccounts} = response;
            this.memberBankAccounts = bankAccounts;
          });
      this.isAddNewLocalDepositSidebarActive = true;
    },
    showDepositDetail(depositData) {
      this.depositData = depositData;
      this.$bvModal.show("modal-deposit-detail");
    },
  },
  setup() {
    // const statusOptions = ref([]);

    const PAYMENT_DEPOSIT_STORE_MODULE_NAME = "payment-deposit";
    const PAYMENT_LOCAL_DEPOSIT_STORE_MODULE_NAME = "payment-local-deposit";
    const LOCAL_SETTING_BANK_STORE_MODULE_NAME = "local-setting-bank";
    const PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME =
      "payment-local-bank-account";
    const PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME = "payment-bank-account";

    // Register module
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);
    if (!store.hasModule(PAYMENT_DEPOSIT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_DEPOSIT_STORE_MODULE_NAME,
        depositStoreModule
      );
    if (!store.hasModule(PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME,
        localBankAccountStoreModule
      );
    if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
      store.registerModule(
        LOCAL_SETTING_BANK_STORE_MODULE_NAME,
        bankStoreModule
      );
    if (!store.hasModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME,
        paymentAccountStoreModule
      );
    if (!store.hasModule(PAYMENT_LOCAL_DEPOSIT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_LOCAL_DEPOSIT_STORE_MODULE_NAME,
        localDepositStoreModule
      );

    // const isAddNewLocalDepositSidebarActive = ref(false);
    //
    // const companyBankAccounts = ref(null);
    // const memberBankAccounts = ref(null);

    // store
    //   .dispatch("payment-local-bank-account/fetchLocalBankAccounts", {
    //     perPage: 50,
    //     page: 1,
    //   })
    //   .then((response) => {
    //     const { localBankAccounts } = response;
    //     companyBankAccounts.value = localBankAccounts;
    //   });
    // store
    //   .dispatch("payment-bank-account/fetchBankAccounts", {
    //     userId: router.currentRoute.params.id,
    //     perPage: 50,
    //     page: 1,
    //   })
    //   .then((response) => {
    //     const { bankAccounts } = response;
    //     memberBankAccounts.value = bankAccounts;
    //   });
    // store
    //   .dispatch("app-user/fetchUserAdjustmentStatusOptions")
    //   .then((response) => {
    //     statusOptions.value = response.data.data.map((val) => {
    //       return {
    //         label: val.name,
    //         value: val.id,
    //       };
    //     });
    //   });

    // const statusOptions = [
    // 	{ label: 'Pending', value: '1' },
    // 	{ label: 'Success', value: '2' },
    // 	{ label: 'Reject/Fail', value: '3' },
    // ]

    const transferModeOptions = [
      { label: "Internet Banking", value: "1" },
      { label: "Cash", value: "2" },
    ];

    const {
      fetchDeposits,
      tableColumns,
      perPage,
      currentPage,
      totalDeposits,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refDepositListTable,
      refetchData,

      resolveStatus,
      resolveType,

      userIdFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      totalMoney,
      statusFilter,
    } = useDepositsList();

    return {
      fetchDeposits,
      tableColumns,
      perPage,
      currentPage,
      totalDeposits,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refDepositListTable,
      refetchData,

      avatarText,
      numberFormat,

      resolveStatus,
      resolveType,

      // statusOptions,
      transferModeOptions,
      // companyBankAccounts,
      // memberBankAccounts,

      // Extra Filters
      userIdFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,

      totalMoney,
      formatDateTime,
    };
  },
};
</script>

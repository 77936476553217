<template>
	<div>
		<b-modal
			id="modal-outstanding-balances"
			ok-only
			ok-title="Accept"
			centered
			size="lg"
			title="Member Outstanding Balances"
			hide-footer
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<b-card
				v-if="fetchCashLogs"
				no-body
			>
				<b-table
					ref="refOutstandingBalancesListTable"
					class="position-relative table-white-space mb-0 max-height-table"
					sticky-header
					head-variant="light"
					:items="fetchCashLogs"
					responsive
					:fields="tableColumns"
					primary-key="index"
					:sort-by.sync="sortBy"
					show-empty
					empty-text="No matching records found"
					:sort-desc.sync="isSortDirDesc"
				>
					<template #cell(index)="data">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</template>

					<template #cell(transactionType)="data">
						{{ data.item.txn_type ? data.item.txn_type.name : 'n/a' }}
					</template>

					<template #cell(netAmount)="data">
						<b-link>
							{{ numberFormat(data.item.net_amount / 1000) }}
						</b-link>
					</template>

					<template #cell(createdAt)="data">
						{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
					</template>

				</b-table>
				<div class="mt-2">
					<b-row>

						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>

							<b-pagination
								v-model="currentPage"
								:total-rows="totalOutstandingBalances"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>

						</b-col>

					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import {
	BOverlay, BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import cashLogStoreModule from '@/views/apps/user/users-detail/cash-log/cashLogStoreModule'

export default {
	components: {
		BButton,
		BOverlay,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		loginLogs: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			show: false,
		}
	},
	setup() {

		const perPage = ref(20)
		const totalOutstandingBalances = ref('')
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const userIdFilter = ref(router.currentRoute.params.id)

		const refOutstandingBalancesListTable = ref(null)

		const toast = useToast()

		const CASH_LOG_STORE_MODULE_NAME = 'app-user-cash-log'

		// Register module
		if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			// if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
		})

		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'ref', label: 'Transaction Id' },
			{ key: 'transactionType', label: 'Transaction Type' },
			{ key: 'netAmount', label: 'balance' },
			'note',
			{ key: 'createdAt', label: 'Created At' },
		]

		const refetchData = () => {
			refOutstandingBalancesListTable.value.refresh()
		}

		watch([currentPage, perPage], () => {
			refetchData()
		})

		const fetchCashLogs = (ctx, callback) => {
			store
				.dispatch('app-user-cash-log/fetchCashLogs', {
					perPage: perPage.value,
					page: currentPage.value,
					sortBy: sortBy.value,
					sortDesc: isSortDirDesc.value,
					userId: userIdFilter.value,
				})
				.then(response => {
					const { cashlogs, total, pageSize } = response
					callback(cashlogs)
					totalOutstandingBalances.value = total
					perPage.value = pageSize
				})
				.catch(() => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error fetching cash logs list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refOutstandingBalancesListTable.value ? refOutstandingBalancesListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalOutstandingBalances.value,
			}
		})

		return {
			fetchCashLogs,
			refetchData,
			tableColumns,
			perPage,
			currentPage,
			totalOutstandingBalances,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refOutstandingBalancesListTable,
			userIdFilter,
			numberFormat,
			formatDateTime,
		}
	},
}
</script>
<template>
	<b-card no-body class="border">
		<b-card-body>
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Event type') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="eventTypeFilter"
						:options="eventTypeOptions"
						class="w-100"
						:reduce="val => val.id"
						label="name"
						@input="(val) => $emit('update:eventTypeFilter', val)"
					/>
				</b-col>
<!--                <b-col-->
<!--					cols="12"-->
<!--					md="4"-->
<!--					class="mb-md-0 mb-2"-->
<!--				>-->
<!--					<label>{{ $t('Activity') }}</label>-->
<!--					<v-select-->
<!--						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--						:value="activityFilter"-->
<!--						:options="activityOptions"-->
<!--						class="w-100"-->
<!--						:reduce="val => val.value"-->
<!--						@input="(val) => $emit('update:activityFilter', val)"-->
<!--					/>-->
<!--				</b-col>-->
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Ip</label>
					<b-form-input
						v-model="filter.clientIp"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="outline-secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		BButton,
		vSelect,
	},
	props: {
		eventTypeOptions: {
			type: Array,
			default: null,
		},
		activityOptions: {
			type: Array,
			default: null,
		},
		eventTypeFilter: {
			type: Number,
			default: null,
		},
		activityFilter: {
			type: String,
			default: null,
		},
		clientIpFilter: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			filter: {
				clientIp: this.clientIpFilter,
				eventType: this.eventTypeFilter,
				activity: this.activityFilter,
			},
		}
	},
	methods: {
		resetFilter() {
			this.$emit('update:clientIpFilter', null)
			this.$emit('update:eventTypeFilter', null)
			this.$emit('update:activityFilter', null)
			this.filter = {
				clientIp: null,
				eventType: null,
				activity: null,
			}
		},
		searchFilter() {
			this.$emit('update:clientIpFilter', this.filter.clientIp)
			this.$emit('update:eventTypeFilter', this.filter.eventType)
			this.$emit('update:activityFilter', this.filter.activity)
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

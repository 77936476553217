/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <adjustment-list-add-new
      v-if="balanceType"
      :is-add-new-adjustment-sidebar-active.sync="
        isAddNewAdjustmentSidebarActive
      "
      :type-options="typeOptions"
      :balance-type="balanceType"
      @refetch-data="refetchData"
    />
    <!-- Filters -->
    <adjustments-list-filters
      v-if="balanceType"
      :username-filter.sync="usernameFilter"
      :email-filter.sync="emailFilter"
      :phone-filter.sync="phoneFilter"
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :type-filter.sync="typeFilter"
      :balance-filter.sync="balanceFilter"
      :balance-type.sync="balanceType"
      :type-options="typeOptions"
      :status-options="statusOptions"
      :status-filter.sync="statusFilter"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col v-if="$can('create', 'adjustbalance')" cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="handleClickAddNewAdjustment()"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusCircleIcon" />
                  {{ $t("Create") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div style="margin: 8px 0px; color: white">
        Tổng tiền:
        {{ totalMoney ? numberFormat(parseFloat(totalMoney) / 1000) : 0 }}
      </div>

      <b-table
        ref="refAdjustmentListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchAdjustments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(reference)="data">
          <b-link>
            {{ data.item.ref }}
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          {{ data.item.user.username }}
        </template>

        <template #cell(currency)="data">
          {{ userData.currency }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />
            {{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(amount)="data">
          {{ numberFormat(parseFloat(data.item.amount) / 1000) }}
        </template>

        <template #cell(batchAdjustmentName)="data">
          {{
            data.item.batchAdjustmentName ? data.item.batchAdjustmentName : "-"
          }}
        </template>

        <template #cell(type)="data">
          <b-badge
            pill
            :variant="`light-${resolveAdjustmentTypeTransactionVariant(
              data.item.type
            )}`"
            class="text-capitalize"
          >
            {{ resolveAdjustmentTypeTransactionLabel(data.item.type) }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdjustments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatDateTime,
  resolveCurrency,
} from "@core/utils/filter";
import adjustmentStoreModule from "@/views/payments/adjustment/adjustmentStoreModule";
import AdjustmentsListFilters from "./adjustment/AdjustmentsListFilters.vue";
import useAdjustmentsList from "./adjustment/useAdjustmentsList";
import AdjustmentListAddNew from "./adjustment/AdjustmentListAddNew.vue";
import userStoreModule from "../userStoreModule";

export default {
  components: {
    AdjustmentsListFilters,
    AdjustmentListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: [],
    }
  },
  data() {
    return {
      balanceType: [],
      isAddNewAdjustmentSidebarActive: false
    }
  },
  methods: {
    async handleClickAddNewAdjustment() {
      await store.dispatch("app-user/fetchUserAdjustmentType").then((response) => {
        this.balanceType = response.data.data;
      });
      this.isAddNewAdjustmentSidebarActive = true
    }
  },
  setup() {
    const resolveUserBalanceType = (userBalanceType, balanceTypeId) => {
      const val = userBalanceType.find((e) => e.id === balanceTypeId);
      return val.name;
    };

    const PAYMENT_ADJUSTMENT_STORE_MODULE_NAME = "payment-adjustment";

    // Register module
    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);
    if (!store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_ADJUSTMENT_STORE_MODULE_NAME,
        adjustmentStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME);
    });

    // store
    //   .dispatch("app-user/fetchUserAdjustmentStatusOptions")
    //   .then((response) => {
    //     statusOptions.value = response.data.data.map((val) => {
    //       return {
    //         label: val.name,
    //         value: val.id,
    //       };
    //     });
    //   });

    const typeOptions = [
      { label: "Plus", value: "1" },
      { label: "Minus", value: "2" },
    ];

    // const statusOptions = [
    //   { label: "pending", value: "1" },
    //   { label: "success", value: "2" },
    //   { label: "reject", value: "3" },
    // ];

    const {
      fetchAdjustments,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdjustmentListTable,
      refetchData,

      // UI
      resolveAdjustmentTypeTransactionVariant,
      resolveAdjustmentTypeTransactionLabel,
      resolveStatus,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      typeFilter,
      balanceFilter,
      statusFilter,
      totalMoney,
    } = useAdjustmentsList();

    return {
      fetchAdjustments,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdjustmentListTable,
      refetchData,
      resolveUserBalanceType,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      // UI
      resolveAdjustmentTypeTransactionVariant,
      resolveAdjustmentTypeTransactionLabel,
      resolveStatus,

      typeOptions,
      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      typeFilter,
      balanceFilter,
      statusFilter,
      totalMoney,
    };
  },
};
</script>

<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Bonus ID -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Bonus ID") }}</label>
            <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="search.bonus_id"
                :options="bonusIdOptions"
                class="w-100"
                :reduce="(val) => val.value"
            />
          </b-col>

          <!-- Status -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Status") }}</label>
            <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="search.status"
                :options="statusOptions"
                class="w-100"
                :reduce="(val) => val.value"
            />
            <!-- @input="(val) => $emit('update:bonusCodeIdFilter', val)" -->
          </b-col>

          <b-col cols="12" md="3" class="mb-2">
            <label>{{ $t("From Date") }}</label>
            <flat-pickr
                id="fromDate"
                :config="formatFromDate"
                v-model="search.fromDate"
                class="form-control"
                placeholder="YYYY-MM-DD hh:mm:ss"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-2">
            <label>{{ $t("To Date") }}</label>
            <flat-pickr
                id="fromDate"
                :config="formatFromDate"
                v-model="search.toDate"
                class="form-control"
                placeholder="YYYY-MM-DD hh:mm:ss"
            />
          </b-col>
          <!-- action -->
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
          ref="refUserBonusRequestListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="userBonusRequestList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.item.index + 1 }}
        </template>

        <template #cell(status)="data">
          <span v-if="data.item.statusId === 1" class="text-warning">
            {{ data.item.status }}
          </span>
          <span v-if="data.item.statusId === 2" class="text-success">
            {{ data.item.status }}
          </span>
          <span v-if="data.item.statusId === 3" class="text-secondary">
            {{ data.item.status }}
          </span>
          <span v-if="data.item.statusId === 4" class="text-danger">
            {{ data.item.status }}
          </span>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalSportTeam"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Edit User Bonus Request Modal -->
    <edit-user-bonus-request-modal
        :user-bonus-detail.sync="userBonusDetail"
        @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
import moment from "moment";
import EditUserBonusRequestModal from "@/views/bonus/EditUserBonusRequestModal";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    EditUserBonusRequestModal,
    flatPickr,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userBonusDetail: null,
    };
  },
  setup(props) {
    const toast = useToast();
    if (!store.hasModule("bonus"))
      store.registerModule("bonus", bonusStoreModule);
    onUnmounted(() => {
      if (store.hasModule("bonus")) store.unregisterModule("bonus");
    });

    const search = ref({
      username: "",
      bonus_id: "",
      status: "",
      fromDate: "",
      toDate: "",
    });
    const statusOptions = [
      { label: "Pending", value: 1 },
      { label: "Success", value: 2 },
      { label: "Reject", value: 3 },
      { label: "Cancel", value: 4 },
    ];
    const formatFromDate = {
      enableTime: true,
      dateFormat: "Y-m-d H:i:s",
    };
    const formatToDate = {
      enableTime: true,
      dateFormat: "Y-m-d H:i:s",
    };
    const bonusIdOptions = ref([]);
    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);

    const refUserBonusRequestListTable = ref(null);
    const tableColumns = [
      { key: "index", label: "#" },
      // { key: "username", label: "Username" },
      { key: "bonusName", label: "Bonus Name" },
      { key: "ip", label: "IP" },
      { key: "remark", label: "Remark" },
      { key: "status", label: "Status" },
      { key: "updated_by", label: "Updated By" },
      { key: "created_at", label: "Created At" },
    ];

    const dataMeta = computed(() => {
      const localItemsCount = refUserBonusRequestListTable.value
          ? refUserBonusRequestListTable.value.localItems.length
          : 0;
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const refetchData = () => {
      refUserBonusRequestListTable.value.refresh();
      getListUserBonusRequest();
    };

    watch([currentPage], () => {
      refetchData();
    });
    const userBonusRequestList = ref();
    console.log('props.userData.username', props.userData.username)
    const getListUserBonusRequest = (ctx, callback) => {
      store
          .dispatch("bonus/fetchUserBonusRequest", {
            username: props.userData.username,
            bonus_id: search.value.bonus_id,
            status: search.value.status,
            fromDate: search.value.fromDate,
            toDate: search.value.toDate,
            pageNumber: currentPage.value,
          })
          .then((response) => {
            if (response.data.code == "00") {
              let result = response.data.data;
              userBonusRequestList.value = Object.values(result).map(
                  (item, index) => {
                    let status = statusOptions.filter(
                        (x) => x.value === item.status
                    )[0].label;
                    return {
                      ...item,
                      bonusName: item.bonus_code.name,
                      username: item.user.username,
                      statusId: item.status,
                      status: status,
                      index: index,
                    };
                  }
              );
              totalSportTeam.value = response.data.count;
            }else{
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
    };
    getListUserBonusRequest();
    store.dispatch("bonus/fetchBonusCodes", {}).then((response) => {
      bonusIdOptions.value = response.data.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

    const resetFilter = () => {
      // search.value.name = "";
      search.value.username = "";
      search.value.bonus_id = "";
      search.value.status = "";
      search.value.fromDate = "";
      search.value.toDate = "";
      refetchData();
    };

    const searchFilter = () => {
      getListUserBonusRequest();
    };

    return {
      bonusIdOptions,
      tableColumns,
      statusOptions,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      refUserBonusRequestListTable,
      getListUserBonusRequest,
      refetchData,
      search,
      resetFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      userBonusRequestList,
      formatFromDate,
      formatToDate,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

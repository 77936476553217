<template>
	<div class="user-view-detail">

		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="userData === undefined"
		>
			<h4 class="alert-heading">
				Error fetching user data
			</h4>
			<div class="alert-body">
				No user found with this user id. Check
				<b-link
					class="alert-link"
					:to="{ name: 'apps-users-list'}"
				>
					User List
				</b-link>
				for other users.
			</div>
		</b-alert>

		<template v-if="userData">
			<!-- First Row -->
			<b-row>
				<b-col
					cols="12"
				>
					<user-view-user-info-card :user-data="{userData, loginLogs, balances, balancesList}" />
				</b-col>
			</b-row>
		</template>

	</div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
	BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

export default {
	components: {
		BRow,
		BCol,
		BAlert,
		BLink,

		// Local Components
		UserViewUserInfoCard,
	},
	setup() {
		const userData = ref(null)
		const loginLogs = ref(null)

		const balances = ref('');
		const balancesList = ref(null);

		const balanceType = ref(null)

		// const provinceLists = ref(null)

		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})

		/* store.dispatch('app-user/fetchUserBalanceType')
			.then(response => {
				balanceType.value = response.data.data
			}) */
		store.dispatch('app-user/fetchUserBalance', router.currentRoute.params.id)
			.then(response => {
        balancesList.value = response.data.data;
				balances.value = response.data.data ? response.data.data.find(item => item.balance_type.id == 1) : ''
			})
		// store.dispatch('app-user/fetchProvinces')
		// 	.then(response => {
		// 		provinceLists.value = response.data.data
		// 	})

		store.dispatch('app-user/fetchUserDetail', router.currentRoute.params.id)
			.then(response => {
				userData.value = response.data.data;
        loginLogs.value = response.data.data.loginLogs;
			})
			.catch(error => {
				userData.value = undefined
			})

		return {
			balances,
      balancesList,
			balanceType,
			userData,
      loginLogs
			// provinceLists,
		}
	},
}
</script>

<style>

</style>

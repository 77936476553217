<template>
	<div>
		<!-- Filters -->
		<audit-logs-list-filters
			:event-type-options="eventTypeOptions"
			:activity-options="activityOptions"
			:event-type-filter.sync="eventTypeFilter"
			:activity-filter.sync="activityFilter"
			:client-ip-filter.sync="clientIpFilter"
		/>
		<b-card
			no-body
			class="mb-0"
		>
			<b-table
				ref="refAuditLogListTable"
				class="position-relative table-white-space mb-0 max-height-table"
				sticky-header
				head-variant="light"
				:no-border-collapse="true"
				:items="fetchUserAuditLogs"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(eventTypeName)="data">
					{{ data.item.manager_function ? data.item.manager_function.name : 'n/a' }}
				</template>

				<template #cell(activity)="data">
					{{ data.item.manager_function ? resolveActivity(data.item.manager_function.action) : 'n/a' }}
				</template>

				<template #cell(updatedBy)="data">
					{{ data.item.staff ? data.item.staff.username : 'n/a' }}
				</template>

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
				</template>

				<!-- Column: Actions -->
				<template #cell(action)="data">
					<div class="text-nowrap">
						<b-link
							@click="showAuditLogDetailModal(data.item.id, {
								eventTypeName: data.item.manager_function ? data.item.manager_function.name : 'n/a',
								activity: data.item.manager_function ? resolveActivity(data.item.manager_function.action) : 'n/a',
								staff: data.item.staff ? data.item.staff.username : 'n/a',
								tableName: data.item.table_name,
							})"
						>
							<feather-icon icon="EyeIcon" size="16"/>
						</b-link>
					</div>
				</template>

			</b-table>
			<div class="mx-2 mt-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalAuditLogs"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Modal Audit Log Detail -->
		<audit-log-detail-modal
			:audit-log-id.sync="auditLogId"
			:detail-datas="detailDatas"
		/>
	</div>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BAlert, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import store from '@/store'
import useAuditLogsList from './audit-log/useAuditLogsList'
import AuditLogsListFilters from './audit-log/AuditLogsListFilters.vue'

export default {
	components: {
		AuditLogDetailModal: () => import('./audit-log/AuditLogDetailModal.vue'),
		BAlert,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,

		AuditLogsListFilters,
	},
	directives: {
		'b-modal': VBModal,
	},
	data() {
		return {
			auditLogId: -1,
			detailDatas: {},
		}
	},
	props: {
		loginLogs: {
			type: Array,
			default: null,
		},
	},
	methods: {
		showAuditLogDetailModal(id, datas) {
			this.auditLogId = id
			this.detailDatas = datas
			this.$bvModal.show('modal-audit-log-detail')
		},
	},
	setup() {

		const resolveActivity = (a) => {
			if (a.indexOf('edit') > -1) return 'Edit'
			if (a.indexOf('view') > -1) return 'View'
			if (a.indexOf('update') > -1) return 'Update'
			if (a.indexOf('create') > -1) return 'Create'
			if (a.indexOf('change') > -1) return 'Change'
		}

		const {
			fetchAuditLogs,
			fetchUserAuditLogs,
			tableColumns,
			perPage,
			currentPage,
			totalAuditLogs,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refAuditLogListTable,
			eventTypeOptions,
			activityOptions,

			refetchData,

			// Extra Filters
			eventTypeFilter,
			activityFilter,
			clientIpFilter,
		} = useAuditLogsList()

		return {
			fetchAuditLogs,
			fetchUserAuditLogs,
			tableColumns,
			perPage,
			currentPage,
			totalAuditLogs,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refAuditLogListTable,
			eventTypeOptions,
			activityOptions,

			refetchData,

			// Extra Filters
			eventTypeFilter,
			activityFilter,
			clientIpFilter,

			resolveActivity,

			formatDateTime,
		}
	},
}
</script>
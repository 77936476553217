import { ref, watch, computed } from '@vue/composition-api'
import { title, paginateArray, sortCompare } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useAuditLogsList() {
	// Use toast
	const toast = useToast()

	const fetchAuditLogs = [
		{ eventTypeName: 'View member phone', tableName: 'member', activity: 'select', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
		{ eventTypeName: 'View member email', tableName: 'member', activity: 'select', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
		{ eventTypeName: 'View member phone', tableName: 'member', activity: 'select', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
		{ eventTypeName: 'Change member phone', tableName: 'member', activity: 'update', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
		{ eventTypeName: 'Update member email', tableName: 'member', activity: 'update', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
		{ eventTypeName: 'View member phone', tableName: 'member', activity: 'select', updatedBy: 'superuser', clientIp: '112.201.77.125', remarks: '', createdAt: '2020-01-01 00:00:00' },
	]

	const refAuditLogListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'eventTypeName', label: i18n.t('Event type') },
		{ key: 'table_name', label: i18n.t('Table Name') },
		{ key: 'activity', label: i18n.t('Activity') },
		{ key: 'updatedBy', label: i18n.t('Updated by') },
		{ key: 'ip', label: 'IP'},
		{ key: 'note', label: i18n.t('Remark') },
		{ key: 'createdAt', label: i18n.t('Created at') },
		{ key: 'action', label: i18n.t('Actions') },
	]
	const perPage = ref(20)
	const totalAuditLogs = ref(fetchAuditLogs.length)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const eventTypeFilter = ref(null)
	const activityFilter = ref(null)
	const clientIpFilter = ref(null)
	const eventTypeOptions = ref([])
	const activityOptions = ref([])

	const fetchUserAuditLogs = (ctx, callback) => {
		store
			.dispatch('app-user/fetchUserAuditLogs', {
				userId: router.currentRoute.params.id,
				eventType: eventTypeFilter.value,
				activity: activityFilter.value,
				clientIp: clientIpFilter.value,
				page: currentPage.value,
			})
			.then(response => { 
				const { auditLogs, total, pageSize } = response
				callback(auditLogs)
				totalAuditLogs.value = total
				perPage.value = pageSize
			})
	}

	store
		.dispatch('app-user/fetchUserAuditLogEventType')
		.then(response => { 
			eventTypeOptions.value = response.data.data
		})

	const dataMeta = computed(() => {
		const localItemsCount = refAuditLogListTable.value ? refAuditLogListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalAuditLogs.value,
		}
	})

	const refetchData = () => {
		refAuditLogListTable.value.refresh()
	}

	watch([currentPage], () => {
		refetchData()
	})

	return {
		fetchAuditLogs,
		fetchUserAuditLogs,
		tableColumns,
		perPage,
		currentPage,
		totalAuditLogs,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refAuditLogListTable,
		eventTypeOptions,
		activityOptions,

		refetchData,

		// Extra Filters
		eventTypeFilter,
		activityFilter,
		clientIpFilter,
	}
}

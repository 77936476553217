/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <withdrawals-list-filters
      :username-filter.sync="usernameFilter"
      :email-filter.sync="emailFilter"
      :phone-filter.sync="phoneFilter"
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />
    <b-card no-body class="mb-0">
      <div style="margin: 8px 0px; color: white">
        Tổng tiền: {{ numberFormat(parseFloat(totalMoney) / 1000) }}
      </div>
      <b-table
        ref="refWithdrawalListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchWithdrawals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(reference)="data">
          <b-link>
            {{ data.item.reference }}
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          {{ data.item.user.username }}
        </template>

        <template #cell(amount)="data">
          {{ numberFormat(parseFloat(data.item.amount) / 1000) }}
        </template>

        <template #cell(paymentType)="data">
          {{ resolvePaymentType(data.item.type) }}
        </template>

        <template #cell(currency)="data">
          {{ userData.currency }}
        </template>

        <template #cell(fee)="data">
          {{ numberFormat(parseFloat(data.item.fee) / 1000) }}
        </template>
        <template #cell(net_amount)="data">
          {{
            numberFormat(parseFloat(data.item.net_amount) / 1000) 
          }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div
            :class="`text-capitalize align-items-center d-flex`"
            v-if="data.item.txn_status"
          >
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />{{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalWithdrawals"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- modal Confirm-->
    <b-modal
      id="modal-confirm-reject"
      cancel-variant="outline-secondary"
      ok-title="Confirm"
      cancel-title="Close"
      centered
      title="Reject Reason"
      @ok="confirmUpdateStatus"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
            id="rejectReason"
            v-model="withdrawalStatus.rejectReason"
            placeholder="Reject Reason"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatDateTime,
  resolveCurrency,
} from "@core/utils/filter";
import store from "@/store";
import WithdrawalsListFilters from "./withdrawal/WithdrawalsListFilters.vue";
import useWithdrawalsList from "./withdrawal/useWithdrawalsList";
import withdrawalStoreModule from "@/views/payments/withdrawal/withdrawalStoreModule";

export default {
  components: {
    WithdrawalsListFilters,

    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      withdrawalStatus: {
        withdrawalId: "",
        status: "",
        rejectReason: null,
      },
    };
  },
  methods: {
    updateStatus(withdrawalId, value) {
      this.withdrawalStatus.withdrawalId = withdrawalId;
      this.withdrawalStatus.status = value;
      if (value === "6") {
        this.$bvModal.show("modal-confirm-reject");
      } else {
        this.$bvModal
          .msgBoxConfirm("Bạn có chắc muốn cập nhật trạng thái rút tiền?", {
            cancelVariant: "outline-secondary",
          })
          .then((value) => {
            if (value) {
              this.confirmUpdateStatus();
            }
          });
      }
    },
    confirmUpdateStatus() {
      this.updateWithdrawalStatus(this.withdrawalStatus);
      this.refetchData();
    },
  },
  setup() {
    const PAYMENT_WITHDRAWAL_STORE_MODULE_NAME = "payment-withdrawal";

    // Register module
    if (!store.hasModule(PAYMENT_WITHDRAWAL_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_WITHDRAWAL_STORE_MODULE_NAME,
        withdrawalStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_WITHDRAWAL_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_WITHDRAWAL_STORE_MODULE_NAME);
    });

    // const rejectReason = ref('')

    const statusOptions = [
      { label: "pending", value: "1" },
      { label: "inprocess", value: "2" },
      { label: "fail", value: "3" },
      { label: "success", value: "4" },
      // { label: "request", value: "5" },
      { label: "reject", value: "6" },
      { label: "admin approved", value: "7" },
    ];

    const statusDefault = ref({ label: "request process admin", value: "7" });

    const resolvePaymentType = (pt) => {
      if (pt === 1) return "1Click Pay";
      if (pt === 2) return "WLBT";
      return "n/a";
    };

    // const updateStatus = (withdrawalId, value) => {
    // 	console.log(withdrawalId + '-' + value)
    // 	$bvModal.show('modal-login')
    // }

    const {
      fetchWithdrawals,
      tableColumns,
      perPage,
      currentPage,
      totalWithdrawals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWithdrawalListTable,
      refetchData,
      resolveStatus,

      updateWithdrawalStatus,

      // UI
      resolveWithdrawalStatusVariant,
      resolveWithdrawalStatusStyle,

      // Extra Filters
      usernameFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      totalMoney,
    } = useWithdrawalsList();

    return {
      fetchWithdrawals,
      tableColumns,
      perPage,
      currentPage,
      totalWithdrawals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWithdrawalListTable,
      refetchData,
      resolveStatus,

      updateWithdrawalStatus,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      // UI
      resolveWithdrawalStatusVariant,
      resolveWithdrawalStatusStyle,
      resolvePaymentType,

      statusOptions,

      // rejectReason,

      // Extra Filters
      usernameFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      statusDefault,totalMoney
      // updateStatus,
    };
  },
};
</script>

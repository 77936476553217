import WithdrawalService from '@/libs/withdrawal.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchWithdrawals(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				WithdrawalService.getWithdrawalLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateWithdrawalStatus(ctx, withdrawalData) {
			return new Promise((resolve, reject) => {
				WithdrawalService.updateWithdrawal(withdrawalData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		exportWithdrawals(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				WithdrawalService.exportWithdrawals(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}

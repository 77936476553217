<template>
  <b-sidebar
    id="add-new-adjustment-sidebar"
    :visible="isAddNewAdjustmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-adjustment-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ $t("Add new adjustment") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Field: Transaction Type -->
          <b-form-group label-for="transactionType">
            <label class="mb-2"
              ><span class="text-danger">*</span>
              {{ $t("Transaction type") }}</label
            >
            <b-form-radio-group
              id="transactionType"
              v-model="adjustmentData.type"
              :options="transactionTypes"
              value="1"
            />
          </b-form-group>

          <!-- Amount -->
          <validation-provider
            #default="validationContext"
            name="Amount"
            rules="required|numeric|min_value:1"
          >
            <b-form-group label-for="amount">
              <label class="mb-1"
                ><span class="text-danger">*</span> {{ $t("Amount") }}</label
              >
              <b-form-input
                id="amount"
                v-model="adjustmentData.amount"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Reason -->
          <validation-provider
            #default="validationContext"
            name="reason"
            rules="required"
          >
            <b-form-group
              label-for="reason"
              :state="getValidationState(validationContext)"
            >
              <label class="mb-1"
                ><span class="text-danger">*</span> {{ $t("Remark") }}</label
              >
              <v-select
                v-model="adjustmentData.note"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="balanceType.filter((e) => e.name )"
                :reduce="(val) => val.name"
                label="name"
                input-id="reason"
                :selectable="
                  (item) => item.name !== adjustmentData.note
                "
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Note -->
          <!-- <b-form-group :label="$t('Note')" label-for="note">
            <b-form-textarea
              v-model="adjustmentData.reason"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="note"
            />
          </b-form-group> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { ref } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import router from "@/router";
import adjustmentStoreModule from "@/views/payments/adjustment/adjustmentStoreModule";
import {onUnmounted} from "@vue/composition-api/dist/vue-composition-api";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewAdjustmentSidebarActive",
    event: "update:is-add-new-adjustment-sidebar-active",
  },
  props: {
    isAddNewAdjustmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    balanceType: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const toast = useToast();
    const options = ref([]);
    const blankAdjustmentData = {
      userId: router.currentRoute.params.id,
      type: 1,
      balanceType: "",
      transactionType: 1,
      reason: "",
      amount: "",
      note: null,
    };

    const adjustmentData = ref(JSON.parse(JSON.stringify(blankAdjustmentData)));
    const resetadjustmentData = () => {
      adjustmentData.value = JSON.parse(JSON.stringify(blankAdjustmentData));
    };

    const transactionTypes = [
      { text: "Credit", value: "1" },
      { text: "Debit", value: "2" },
    ];

    const onSubmit = () => {
      store
        .dispatch("payment-adjustment/addAdjustment", adjustmentData.value)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.code == "00") {
              emit("refetch-data");
              emit("update:is-add-new-adjustment-sidebar-active", false);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        })
        .catch((error) => {
          const { response } = error;
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetadjustmentData);

    const onSearch = (value, loading) => {
      if (value.length) {
        loading(true);
        search(loading, value);
      }
    };

    const search = (loading, value) => {
      store
        .dispatch("app-user/fetchUsers", {
          username: value,
          // email: value,
          // fullname: value,
          // phone: value,
        })
        .then((res) => {
          options.value = res.users;
          loading(false);
        });
    };

    return {
      adjustmentData,
      onSubmit,
      options,
      transactionTypes,

      refFormObserver,
      getValidationState,
      resetForm,
      onSearch,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

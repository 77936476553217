<template>
	<div>
		<b-modal
			id="modal-account-statement-total-detail"
			cancel-variant="outline-secondary"
			ok-only
			ok-title="Ok"
			centered
			size="xl"
			:title="total.des"
		>
			<b-card
				v-if="fetchCashLogs"
				no-body
				class="border"
			>
				<b-table
					ref="refAccountStatementTotalDetailsListTable"
					class="position-relative table-white-space mb-0 max-height-table"
					sticky-header
					head-variant="light"
					:no-border-collapse="true"
					:items="fetchCashLogs"
					responsive
					:fields="tableColumns"
					primary-key="index"
					:sort-by.sync="sortBy"
					show-empty
					empty-text="No matching records found"
					:sort-desc.sync="isSortDirDesc"
				>
					<template #cell(index)="data">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</template>

					<template #cell(ref)="data">
						<b-link>
							{{ data.item.ref }}
						</b-link>
					</template>

					<template #cell(currency)="data">
						{{ userCurrency }}
					</template>

					<template #cell(amount)="data">
						<b-link>
							{{ numberFormat(parseFloat(data.item.amount) / 1000) }}
						</b-link>
					</template>

					<template #cell(createdAt)="data">
						{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
					</template>

					<template #cell(status)="data">
						<span :class="`dot bg-${resolveStatus(data.item.stat).variant}`" />
						{{ resolveStatus(data.item.stat).label }}
					</template>

				</b-table>
				<div class="p-2">
					<b-row>

						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>

							<b-pagination
								v-model="currentPage"
								:total-rows="totalAccountStatementTotalDetails"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>

						</b-col>

					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { numberFormat, formatDateTime, resolveCurrency } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import cashLogStoreModule from '@/views/apps/user/users-detail/cash-log/cashLogStoreModule'
import i18n from '@/libs/i18n'

export default {
	components: {
		BButton,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		total: {
			type: Object,
			required: true,
		},
		userCurrency: {
			type: String,
			default: null,
		},
		fromDate: {
			type: String,
			required: true,
		},
		toDate: {
			type: String,
			required: true,
		},
	},
	setup(props) {

		const CASH_LOG_STORE_MODULE_NAME = 'app-user-cash-log'

		// Register module
		if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule)

		const perPage = ref(20)
		const totalAccountStatementTotalDetails = ref('')
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)

		const refAccountStatementTotalDetailsListTable = ref(null)

		const toast = useToast()

		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'createdAt', label: i18n.t('Created at') },
			{ key: 'ref', label: i18n.t('Transaction id') },
			// 'source',
			// 'destination',
			{ key: 'currency', label: i18n.t('Currency') },
			{ key: 'amount', label: i18n.t('Amount') },
			{ key: 'status', label: i18n.t('Status') }
		]

		const refetchData = () => {
			refAccountStatementTotalDetailsListTable.value.refresh()
		}

		watch([currentPage], () => {
			refetchData()
		})

		const fetchCashLogs = (ctx, callback) => {
			store
				.dispatch('app-user-cash-log/fetchCashLogs', {
					page: currentPage.value,
					userId: router.currentRoute.params.id,
					type: props.total.type,
					fromdate: props.fromDate,
					todate: props.toDate,
				})
				.then(response => {
					const { cashlogs, total, pageSize } = response
					callback(cashlogs)
					totalAccountStatementTotalDetails.value = total
					perPage.value = pageSize
				})
				.catch(() => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error fetching cash logs list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refAccountStatementTotalDetailsListTable.value ? refAccountStatementTotalDetailsListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalAccountStatementTotalDetails.value,
			}
		})

		const resolveStatus = status => {
			if (status === 1) return { label: 'Completed', variant: 'success' }
			if (status === 2) return { label: 'Error', variant: 'danger' }
			if (status === 3) return { label: 'Proccess', variant: 'info' }
			return { label: 'none', variant: 'secondary' }
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalAccountStatementTotalDetails,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refAccountStatementTotalDetailsListTable,

			numberFormat,
			formatDateTime,
			resolveCurrency,

			fetchCashLogs,

			resolveStatus,
		}
	},
}
</script>
/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from '@core/utils/filter'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class LocalDepositService {

	getLocalDepositLists(queryParams) {
		const pars = {
			username: queryParams.username,
			userId: queryParams.userId,
			email: queryParams.email,
			name: queryParams.fullname,
			phone: queryParams.phone,
			reference: queryParams.reference,
			fromDate: queryParams.fromdate,
			toDate: queryParams.todate,
			status: queryParams.stat,
			transferMode: queryParams.transferMode,
			pageNumber: queryParams.page,
			pageSize: queryParams.perPage
		}
		return instance.get('/api/local-deposit/index', { headers: authHeader(), params: pars })
				.then(
					response => {
						const {
							q = '',
							perPage = 10,
							page = 1,
							sortBy = 'id',
							sortDesc = false,
							stat = 1,
						} = queryParams
						const queryLowered = q.toLowerCase()
						const filteredData = response.data.data.filter(
							localDeposit =>
								(localDeposit.account_name.toLowerCase().includes(queryLowered) || localDeposit.account_number.toLowerCase().includes(queryLowered)) &&
								localDeposit.status === (stat || localDeposit.status)
						)
						const sortedData = filteredData.sort(sortCompare(sortBy))
						if (sortDesc) sortedData.reverse()

						const datas = {
							localDeposits: (queryLowered) ? paginateArray(sortedData, perPage, page) : response.data.data,
							total: (queryLowered) ? filteredData.length : response.data.count,
							pageSize: queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				)
	}

	addLocalDeposit(localDepositData) {
		const FormData = require('form-data')
		const data = new FormData()
		const image = new FormData()
		// if (localDepositData.attachments.length > 0) {
		// 	for (let i = 0; i < localDepositData.attachments.length; i++) {
		// 		image.append('bills[]', localDepositData.attachments[i])
		// 	}
		// 	return instance.post('api/bill/create'), image,{ headers: authHeader() }
		// }
		data.append('loginname', localDepositData.loginname)
		data.append('bankAccountName', localDepositData.bankAccountName)
		data.append('accountNumber', localDepositData.accountNumber)
		data.append('bankId', localDepositData.bankId)
		data.append('provinceId', localDepositData.provinceId)
		data.append('bankBranch', localDepositData.bankBranch)
		data.append('transferMode', localDepositData.transferMode)
		// data.append('transferDatetime', localDepositData.transferDatetime)
		// data.append('reference', localDepositData.reference)
		data.append('v_bank', localDepositData.vBank)
		data.append('amount', localDepositData.amount * 1000)
		// eslint-disable-next-line no-plusplus
		
		return instance.post('/api/local-deposit/create', data, { headers: authHeader() })
			.then((res)=>{
				if(res?.data?.data?.ref && localDepositData.attachments.length > 0){
					for (let i = 0; i < localDepositData.attachments.length; i++) {
						image.append('bills[]', localDepositData.attachments[i])
					}
					image.append('ref', res.data.data.ref)
					return instance.post('api/bill/create', image,{ headers: authHeader() })
				} else {
					return res;
				}
			})
		
			
	}

	updateLocalDepositStatus(status) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('localDepositId', status.localDepositId)
		data.append('status', status.status)
		data.append('rejectReason', status.rejectReason)

		return instance.post('/api/local-deposit/update-status', data, { headers: authHeader() })
	}
}

export default new LocalDepositService()

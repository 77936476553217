import { ref, watch, computed } from '@vue/composition-api'
import { title, paginateArray, sortCompare } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useTransfersList() {
	// Use toast
	const toast = useToast()

	const refTransferListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'reference', label: i18n.t('Transaction id'), stickyColumn: true },
		// { key: 'partnerReference', label: 'Partner Ref No' },
		{ key: 'username', label: i18n.t('Username')},
		{ key: 'status', label: i18n.t('Status')},
		{ key: 'fromto', label: `${i18n.t('Source')} > ${i18n.t('Destination')}` },
		{ key: 'bonus_code', label: 'Bonus ' },
		{ key: 'bonusAmount', label: 'Bonus Amount' },
		// { key: 'currency', label: i18n.t('Currency') },
		{ key: 'amount', label: i18n.t('Amount') },
		{ key: 'createdAt', label: i18n.t('Created at') },
		// { key: 'updatedAt', label: i18n.t('Updated at') },	
	]
	const perPage = ref(25)
	const totalTransfers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const usernameFilter = ref(null)
	const emailFilter = ref(null)
	const phoneFilter = ref(null)
	const referenceFilter = ref(null)
	const fromdateFilter = ref(null)
	const todateFilter = ref(null)
	const statusFilter = ref(null)
	const balanceTypeFromFilter = ref(null)
	const balanceTypeToFilter = ref(null)
	const createdByFilter = ref(null)

	const refetchData = () => {
		refTransferListTable.value.refresh()
	}

	watch([currentPage, searchQuery, usernameFilter, phoneFilter, emailFilter, referenceFilter, fromdateFilter, todateFilter, statusFilter, balanceTypeFromFilter, balanceTypeToFilter, createdByFilter], () => {
		refetchData()
	})

	const fetchTransfers = (ctx, callback) => {
		store
			.dispatch('payment-transfer/fetchTransfers', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				username: usernameFilter.value,
				email: emailFilter.value,
				phone: phoneFilter.value,
				userId: router.currentRoute.params.id,
				reference: referenceFilter.value,
				fromdate: fromdateFilter.value,
				todate: todateFilter.value,
				stat: statusFilter.value,
				balanceTypeFrom: balanceTypeFromFilter.value,
				balanceTypeTo: balanceTypeToFilter.value,
				createdBy: createdByFilter.value,
			})
			.then(response => {
				const { transfers, total, pageSize } = response
				callback(transfers)
				totalTransfers.value = total
				perPage.value = pageSize
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refTransferListTable.value ? refTransferListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTransfers.value,
		}
	})

	const resolveStatus = stat => {
		if (stat === 2) return { variant: 'info', label: 'Pending' }
		if (stat === 4) return { variant: 'success', label: 'success' }
		return {variant: 'secondary', label: 'None' }
	}

	return {
		fetchTransfers,
		tableColumns,
		perPage,
		currentPage,
		totalTransfers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTransferListTable,

		resolveStatus,
		refetchData,

		// Extra Filters
		usernameFilter,
		emailFilter,
		phoneFilter,
		referenceFilter,
		fromdateFilter,
		todateFilter,
		statusFilter,
		balanceTypeFromFilter,
		balanceTypeToFilter,
		createdByFilter,
	}
}

<template>
	<b-sidebar
		id="add-new-local-deposit-sidebar"
		:visible="isAddNewLocalDepositSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-add-new-local-deposit-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{ $t('Add new local deposit') }}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>

					<!-- TransferMode -->
					<validation-provider
						#default="validationContext"
						name="transferMode"
						rules="required"
					>
						<b-form-group
							label-for="transfer-mode"
							:state="getValidationState(validationContext)"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Transfer mode') }}</label>
							<v-select
								v-model="localDepositData.transferMode"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="transferModeOptions"
								:reduce="val => val.value"
								:clearable="false"
								input-id="transfer-mode"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Company Bank Account -->
					<validation-provider
						#default="validationContext"
						name="companyBankAccount"
						rules="required"
					>
						<b-form-group
							label-for="company-bank-account"
							:state="getValidationState(validationContext)"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Company bank account') }}</label>
							<v-select
								v-model="localDepositData.vBank"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="companyBankAccounts"
								:reduce="val => val.id"
								label="vBank"
								title="Select an item"
								input-id="company-bank-account"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Member Bank Account -->
					<validation-provider
						v-if="localDepositData.transferMode == 1"
						#default="validationContext"
						name="memberBankAccount"
						rules="required"
					>
						<b-form-group
							label-for="member-bank-account"
							:state="getValidationState(validationContext)"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Member bank account') }}</label>
							<v-select
								ref="selectMemberBankAccount"
								v-model="localDepositData.memberBankAccountId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="memberBankAccounts"
								:reduce="val => val.id"
								label="vBank"
								title="Select an item"
								input-id="member-bank-account"
								@input="selectMemberBankAccount"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<div v-if="localDepositData.memberBankAccountId == 0 && localDepositData.transferMode == 1">
						<!-- Bank Account Name -->
						<validation-provider
							#default="validationContext"
							name="BankAccountName"
							rules="required|alpha_spaces|min:3|max:30"
						>
							<b-form-group
								label-for="bank-account-name"
								:state="getValidationState(validationContext)"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Bank account name') }}</label>
								<b-form-input
									id="bank-account-name"
									v-model="localDepositData.bankAccountName"
									:state="getValidationState(validationContext)"
									trim
								/>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Account Number -->
						<validation-provider
							#default="validationContext"
							name="AccountNumber"
							rules="required|numeric"
						>
							<b-form-group
								label-for="account-number"
								:state="getValidationState(validationContext)"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Account number') }}</label>
								<b-form-input
									id="account-number"
									v-model="localDepositData.accountNumber"
									:state="getValidationState(validationContext)"
									trim
								/>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Bank -->
						<validation-provider
							#default="validationContext"
							name="Bank"
							rules="required"
						>
							<b-form-group
								label-for="bank"
								:state="getValidationState(validationContext)"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Bank') }}</label>
								<v-select
									v-model="localDepositData.bankId"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="bankOptions"
									:reduce="val => val.id"
									:clearable="false"
									label="bank_name"
									input-id="bank"
									@input="selectMemberBankId"
								/>
								<b-form-invalid-feedback :state="getValidationState(validationContext)">
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Bank Province -->
						<validation-provider
							#default="validationContext"
							name="Province"
						>
							<b-form-group
								label-for="province"
								:state="getValidationState(validationContext)"
								hidden
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Provinces') }}</label>
								<v-select
									v-model="localDepositData.provinceId"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="provinceLists"
									:reduce="val => val.id"
									:clearable="false"
									label="name"
									input-id="province"
								/>
								<b-form-invalid-feedback :state="getValidationState(validationContext)">
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Bank Branch -->
						<validation-provider
							#default="validationContext"
							name="BankBranch"
						>
							<b-form-group
								label-for="bank-branch"
								:state="getValidationState(validationContext)"
								hidden
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Bank branch') }}</label>
								<b-form-input
									id="bank-branch"
									v-model="localDepositData.bankBranch"
									:state="getValidationState(validationContext)"
									trim
								/>

								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</div>

					<!-- Amount -->
					<validation-provider
						#default="validationContext"
						name="Amount"
						rules="required|numeric|min_value:100|max_value:300000"
					>
						<b-form-group
							label-for="amount"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Amount') }} Gross</label>
							<b-form-input
								id="amount"
								v-model="localDepositData.amount"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Ref Code -->
					<validation-provider
						#default="validationContext"
						name="Refcode"
					>
						<b-form-group
							label-for="refcode"
							hidden
						>
							<label class="mb-1"><span class="text-danger">*</span> Ref Code</label>
							<b-form-input
								id="refcode"
								v-model="localDepositData.refcode"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Attach -->
					<validation-provider
						#default="validationContext"
						name="attachments"
						rules="required|size:10240"
					>
						<b-form-group
							label-for="attachments"
							:state="getValidationState(validationContext)"
						>
							<label class="mb-1"><span class="text-danger">* </span>Attachments</label>
							<b-form-file
								v-model="localDepositData.attachments"
								multiple
								input-id="attachments"
								accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
								@change="uploadFileDeposit($event)"
							/>
							<b-button
								v-show="(localDepositData.attachments.length!==0)"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="localDepositData.attachments=[]"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>	
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{ $t('Add') }}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{ $t('Cancel') }}
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import {
	BFormDatepicker, BSidebar, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupAppend, BInputGroup, BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { ref, computed } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { resolveVBank } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import userStoreModule from '../../userStoreModule'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormFile,
		BFormInvalidFeedback,
		BButton,
		BInputGroupAppend,
		BInputGroup,
		BFormDatepicker,
		BLink,
		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	mixins: [togglePasswordVisibility],
	model: {
		prop: 'isAddNewLocalDepositSidebarActive',
		event: 'update:is-add-new-local-deposit-sidebar-active',
	},
	props: {
		isAddNewLocalDepositSidebarActive: {
			type: Boolean,
			required: true,
		},
		transferModeOptions: {
			type: Array,
			required: true,
		},
		companyBankAccounts: {
			type: Array,
			required: true,
		},
		memberBankAccounts: {
			type: Array,
			required: true,
		},
		userData: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			required,
			alphaNum,
			email,
			bankCode: '',
		}
	},
	watch: {
		localDepositData: {
			deep: true,
			handler(newVal) {
				if (newVal.memberBankAccountId == 0) {
					// this.localDepositData.vBank = resolveVBank(newVal.accountNumber, this.bankCode)
				}
			}
		}
	},
	mounted() {
		this.companyBankAccounts.map(x=> {
			x.vBank = resolveVBank(x.account, x.bank.code)
		})
		this.memberBankAccounts.map(x=> {
			x.vBank = resolveVBank(x.bank_account, x.bank.code)
		})
		const checkMemberBankAccount = this.memberBankAccounts.length < 5
		if (checkMemberBankAccount) {
			this.memberBankAccounts.push({ id: 0, vBank: this.$t('Other') })
		}
	},
	methods: {
		selectMemberBankAccount(value) {
			this.localDepositData.accountNumber = ''
			this.localDepositData.bankId = ''
			// this.localDepositData.provinceId = ''
			this.localDepositData.bankBranch = ''
			// this.localDepositData.vBank = ''
			this.bankCode = ''
			if (value != 0) {
				const data = this.memberBankAccounts.find(item => item.id === value)
				this.localDepositData.accountNumber = data.bank_account
				this.localDepositData.bankId = data.bank_id
				this.localDepositData.bankAccountName = data.user.name
				// this.localDepositData.provinceId = data.provinceId
				this.localDepositData.bankBranch = data.bank_branch
				// this.localDepositData.vBank = data.vBank
			} else {
				this.handleAddMemberBankAccount()
			}
		},
		selectMemberBankId(value) {
			const bankCode = this.bankOptions.find(item => item.id == value)
			this.bankCode = bankCode.code
		},
		handleAddMemberBankAccount() {
			// this.$refs.selectMemberBankAccount.open = false;
		},
		uploadFileDeposit(event) {
			// console.log(event.target.files)
			// this.localDepositData.attachments = event.target.files
			// this.localDepositData.attachments = event.target.files
		},
	},
	setup(props, { emit }) {
		const toast = useToast()
		const datetime = () => new Date().toJSON()
		const blankLocalDepositData = {
			companyBankAccountId: '',
			loginname: props.userData.username,
			bankAccountName: '', // props.userData.name,
			accountNumber: '',
			bankId: '',
			provinceId: '',
			bankBranch: '',
			transferMode: '1',
			// transferDatetime: '', // datetime().replace('T', ' ').substring(0, 16),
			reference: '',
			vBank: '',
			amount: '',
			refcode: '',
			attachments: [],
			ref:'',
		}
		if (!store.hasModule('app-user')) store.registerModule('app-user', userStoreModule)

		const localDepositData = ref(JSON.parse(JSON.stringify(blankLocalDepositData)))
		const resetlocalDepositData = () => {
			localDepositData.value = JSON.parse(JSON.stringify(blankLocalDepositData))
		}

		const vBankOptions = ref([''])
		const provinceLists = ref('')
		const bankOptions = ref('')

		const onSubmit = () => {
			store.dispatch('payment-local-deposit/addLocalDeposit', localDepositData.value)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code === '00') {
							emit('refetch-data')
							emit('update:is-add-new-local-deposit-sidebar-active', false)
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						} else {
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					} else {
						toast({
							component: ToastificationContent,
							props: {
								title: 'unknown Error',
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})
					}
				})
				.catch(error => {
					const { response } = error
					toast({
						component: ToastificationContent,
						props: {
							title: response.data.message || 'Error add Local Deposit',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		// store.dispatch('app-user/fetchProvinces')
		// 	.then(response => {
		// 		provinceLists.value = response.data.data
		// 	})

		store.dispatch('local-setting-bank/fetchBanks', {
			status: null,
		})
			.then(response => {
				bankOptions.value = response
			})

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetlocalDepositData)

		const onContext = (ctx) => {
			localDepositData.transferDatetime = ctx.selectedYMD
		}

		return {
			localDepositData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			onContext,
			vBankOptions,
			provinceLists,
			bankOptions,
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>

<template>
    <div>
        <b-card v-if="fetchCashLogs" no-body class="border">
            <b-table ref="refCashLogsListTable" class="position-relative table-white-space mb-0 max-height-table"
                sticky-header head-variant="light" :no-border-collapse="true" :items="outstandingData" responsive
                :fields="tableColumns" primary-key="index" :sort-by.sync="sortBy" show-empty
                empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

                <template #cell(amount)="data">
                    {{ numberFormat(data.item.amount / 1000) }}
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalCashLogs" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    BLink,
    BBadge,
    BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import store from "@/store";
import router from "@/router";
import cashLogStoreModule from "./cash-log/cashLogStoreModule";
// import CashLogsListFilters from "./cash-log/CashLogsListFilters.vue";
import i18n from "@/libs/i18n";

export default {
    components: {
        // CashLogsListFilters,
        BButton,
        BModal,
        BCard,
        BRow,
        BCol,
        BTable,
        BTr,
        BTh,
        BLink,
        BBadge,
        BPagination,
        vSelect,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {},
    setup() {
        const perPage = ref(1);
        const totalCashLogs = ref("");
        const outstandingData = ref([]);
        const currentPage = ref(1);
        const perPageOptions = [10, 25, 50, 100];
        const sortBy = ref("id");
        const isSortDirDesc = ref(true);

        const refCashLogsListTable = ref(null);
        const toast = useToast();

        const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";

        // Register module
        if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
            store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
        });

        const formatCurrency = (amount) => {
            const floatNumber = parseFloat(amount) / 1000;
            return floatNumber.toLocaleString("vi-VN");
        };


        const tableColumns = [
            'wallet_name', 'amount'
        ];

        const refetchData = () => {
            refCashLogsListTable.value.refresh();
        };

        const fetchCashLogs = (ctx, callback) => {
            store
                .dispatch("app-user-cash-log/fetchOutStandingBalance", {
                    userId: router.currentRoute.params.id,
                })
                .then((response) => {
                    console.log('response', response)
                    if (response.status === 200) {
                        if (response.data.code === '00' || response.data.code === '0') {
                            outstandingData.value = response.data.data.map(item => {
                                return {
                                    product_id: item.product_id,
                                    amount: item.amount,
                                    wallet_name: item.balance_type.wallet_name
                                }
                            })
                        } else {
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error fetching cash logs list",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        fetchCashLogs();

        const dataMeta = computed(() => {
            const localItemsCount = refCashLogsListTable.value
                ? refCashLogsListTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalCashLogs.value,
            };
        });

        return {
            fetchCashLogs,
            refetchData,
            tableColumns,
            perPage,
            currentPage,
            totalCashLogs,
            dataMeta,
            perPageOptions,
            sortBy,
            isSortDirDesc,
            refCashLogsListTable,
            numberFormat,
            formatDateTime,
            formatCurrency,
            outstandingData
        };
    },
};
</script>
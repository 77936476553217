<template>
    <div>
      <b-card no-body>
        <b-table
            ref="refLogRefListTable"
            class="position-relative table-white-space mb-0 max-height-table"
            sticky-header
            head-variant="light"
            :items="logRefList"
            responsive
            :fields="tableColumns"
            primary-key="index"
            show-empty
            empty-text="No matching records found"
        >
          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + data.index + 1 }}
          </template>

           <!-- Column: User -->
        <template #cell(username)="data">
          <div>
            <span v-if="data.item.is_audit == '1'" style="color: orange">
            {{ data.item.username }}
            </span>
            <span v-else>
            {{ data.item.username }}
            </span>
          </div>
        </template>
  
          <template #cell(stat)="data">
            <span v-if="data.item.stat === 1" class="text-success">
              Active
            </span>
            <span v-if="data.item.stat === 2" class="text-warning">
              Inactive
            </span>
            <span v-if="data.item.stat === 3" class="text-secondary">
              Lock
            </span>
          </template>
        </b-table>
        <div class="p-2">
          <b-row>
            <b-col
                cols="12"
                sm="6"
                class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRef"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
  import {formatDateTime, numberFormat} from "@core/utils/filter";
  import store from "@/store";
  import baseUrl from "@/libs/base-url";
  import flatPickr from "vue-flatpickr-component";
  import userStoreModule from "@/views/apps/user/userStoreModule";
  import moment from "moment";
  
  export default {
    components: {
      flatPickr,
      BButton,
      BFormGroup,
      BModal,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BTr,
      BTh,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      BImg,
    },
    props: {
      userData: {
        type: Object,
        required: true,
      },
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    data() {
      return {
        sportTeamDetail: null,
      };
    },
    methods: {},
    setup(props) {
      if (!store.hasModule("user"))
        store.registerModule("user", userStoreModule);
      onUnmounted(() => {
        if (store.hasModule("user")) store.unregisterModule("user");
      });

      const usernameFilter = ref(null);
      const sportTypeId = ref([]);
      const logRefList = ref([]);
      const perPage = ref(25);
      const totalRef = ref(0);
      const currentPage = ref(1);
  
      const refLogRefListTable = ref(null);
      const tableColumns = [
        {key: "index", label: "#", sortable: false},
        {key: "username", label: "Username"},
        {key: "email", label: "Email"},
        {key: "phone", label: "Số điện thoại"},
        {key: "name", label: "Tên"},
        {key: "stat", label: "Trạng thái"},
        {key: "created_at", label: "Created at", sortable: true},
        {key: "is_audit", label: "Is_audit"},
      ];
  
      const refetchData = () => {
        refLogRefListTable.value.refresh();
      };
  
      watch(
          [currentPage],
          () => {
            refetchData();
          }
      );
      const transformedArray = ref();
      const sportTeamLists = (ctx, callback) => {
        store
            .dispatch("user/fetchRefUser", {
              userId: props.userData.id,
              pageNumber: currentPage.value,
            })
            .then((response) => {
              logRefList.value = response.data.data;
              totalRef.value = response.data.count;
            });
      };
  
      store.dispatch("user/fetchRefUser", {
        userId: props.userData.id,
        pageNumber: currentPage.value,
      }).then((response) => {
        logRefList.value = response.data.data;
        totalRef.value = response.data.count;
      });
  
      const dataMeta = computed(() => {
        const localItemsCount = refLogRefListTable.value
            ? refLogRefListTable.value.localItems.length
            : 0;
        return {
          from:
              perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalRef.value,
        };
      });
  
      return {
        logRefList,
        tableColumns,
        perPage,
        currentPage,
        totalRef,
        dataMeta,
        refLogRefListTable,
        sportTeamLists,
        refetchData,
        numberFormat,
        formatDateTime,
        baseUrl,
        sportTypeId,
        transformedArray,
        usernameFilter
      };
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  
import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import i18n from "@/libs/i18n";

export default function useWithdrawalsList() {
  // Use toast
  const toast = useToast();

  const refWithdrawalListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "ref", label: i18n.t("Transaction id") },
    { key: "username", label: i18n.t("Username") },
    { key: "status", label: i18n.t("Status") },
    { key: "paymentType", label: i18n.t("Payment type") },
    { key: "currency", label: i18n.t("Currency") },
    { key: "amount", label: `${i18n.t("Amount")} Gross` },
    { key: "net_amount", label: `${i18n.t("Amount")} Net` },
    { key: "fee", label: i18n.t("Charge amount") },
    { key: "createdAt", label: i18n.t("Created at") },
    { key: "updatedAt", label: i18n.t("Updated at") },
  ];
  const perPage = ref(25);
  const totalWithdrawals = ref(0);
  const currentPage = ref(1);
  const totalMoney = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const userIdFilter = ref(router.currentRoute.params.id);
  const emailFilter = ref(null);
  const phoneFilter = ref(null);
  const referenceFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refWithdrawalListTable.value
      ? refWithdrawalListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWithdrawals.value,
    };
  });

  const refetchData = () => {
    refWithdrawalListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      // perPage,
      // searchQuery,
      // usernameFilter,
      // phoneFilter,
      // emailFilter,
      // userIdFilter,
      // referenceFilter,
      // fromdateFilter,
      // todateFilter,
      // statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchWithdrawals = (ctx, callback) => {
    store
      .dispatch("payment-withdrawal/fetchWithdrawals", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        phone: phoneFilter.value,
        userId: userIdFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        stat: statusFilter.value,
      })
      .then((response) => {
        const { withdrawals, total,money } = response;
        callback(withdrawals);
        totalWithdrawals.value = total;
        totalMoney.value = money;
      });
  };

  const updateWithdrawalStatus = (data) => {
    store
      .dispatch("payment-withdrawal/updateWithdrawalStatus", data)
      .then((response) => {
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Update Withdrawal Status",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const resolveWithdrawalStatusVariant = (stat) => {
    if (stat === 1) return "warning";
    if (stat === 2) return "success";
    if (stat === 3) return "danger";
    if (stat === 4) return "primary";
    if (stat === 5) return "info";
    if (stat === 6) return "danger";
    if (stat === 7) return "warning";
    return "danger";
  };

  const resolveWithdrawalStatusStyle = (stat) => {
    if (stat === 1) return "pending";
    if (stat === 2) return "success";
    if (stat === 3) return "fail";
    if (stat === 4) return "pending because user inactive";
    if (stat === 5) return "request";
    if (stat === 6) return "reject";
    if (stat === 7) return "request process admin";
    return "None";
  };
  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  return {
    fetchWithdrawals,
    tableColumns,
    perPage,
    currentPage,
    totalWithdrawals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWithdrawalListTable,
    resolveStatus,

    resolveWithdrawalStatusVariant,
    resolveWithdrawalStatusStyle,
    refetchData,

    updateWithdrawalStatus,

    // Extra Filters
    usernameFilter,
    userIdFilter,
    emailFilter,
    phoneFilter,
    referenceFilter,
    fromdateFilter,
    todateFilter,
    statusFilter,totalMoney
  };
}

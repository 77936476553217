<template>
	<div>
		<b-modal
			id="modal-update-phone"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			centered
			:title="$t('Update phone')"
			@ok="confirmUpdatePhone"
		>
			<!-- form -->
			<validation-observer ref="changeUserPhone">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormPhone"
				>
					<!-- Field: Status -->
					<b-form-group
						:label="$t('Phone')"
						label-for="phone"
					>
						<validation-provider
							#default="{ errors }"
							name="phone"
							rules="required|numeric|min:10|max:11"
						>
							<b-form-input
								id="phone"
								v-model="uData.phone"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:class="errors.length > 0 ? 'is-invalid':null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { formatRemoveTime, hiddenEmail } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
		updatePhone: {
			type: String,
			required: true,
			default: null
		}
	},
	data() {
		return {
			stat: this.userData.stat,
			note: '',
			uData: {
				fullName: this.userData.name,
				username: this.userData.username,
				email: this.userData.email,
				emailVerify: this.userData.email_verified,
				password: '',
				phone: '',
				birthday: this.userData.dob,
				levelId: this.userData.level_id,
				stat: this.userData.stat,
				userId: this.userData.id,
				country: 1,
				affId: this.userData.agency_id,
				currency: this.userData.currency,
				note: '',
				auto_withdrawal: '',
			}
		}
	},
	methods: {
		validationFormPhone() {
			this.$refs.changeUserPhone.validate().then(success => {
				if (success) {
					store.dispatch('app-user/updateUser', this.uData)
						.then(response => {
							if (response.data.code === '00') {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success',
									},
								})
								this.$nextTick(() => {
									this.$bvModal.hide('modal-update-phone')
									this.resetModal()
								})
								this.$emit('update:updatePhone', this.uData.phone)
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'AlertTriangleIcon',
										variant: 'danger',
									},
								})
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Update User',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetModal() {
			this.uData.phone = ''
		},
		confirmUpdatePhone(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormPhone()
		}
	},
	setup() {
	},
}
</script>

<style>

</style>

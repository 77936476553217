<template>
  <div v-if="$can('view', 'userdetail')">
    <!-- User Info: Input Fields -->
    <h3 class="mb-3">{{ $t("Advance settings") }}</h3>

    <b-form>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Code đại lý')" label-for="affiliateId">
            <b-form-input id="affiliateId" v-model="uData.affId" type="text" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group :label="$t('Email xác thực')" label-for="emailVerify">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="uData.emailVerify"
              :options="emailOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Phone xác thực')"
            label-for="phone_verified"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="uData.phoneVerify"
              :options="phoneOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-button
      v-if="$can('edit', 'user')"
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 float-right"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="saveUser"
    >
      {{ $t("Save change") }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText, formatRemoveTime } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import useUsersList from "../users-list/useUsersList";
import store from "@/store";
import userStoreModule from "../userStoreModule";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      // required: true,
      default: null,
    },
    levelLists: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const toast = useToast();
    // const { resolveUserRoleVariant } = useUsersList();

    const blankUserData = {
      fullName: props.userData.name,
      username: props.userData.username,
      email: props.userData.email,
      emailVerify: props.userData.email_verified,
      phoneVerify: props.userData.phone_verified,
      my_referral: props.userData.my_referral,
      my_referral_link: props.userData.my_referral_link,
      referralCode: props.userData.referral_code,
      phoneVerifiedAt: props.userData.phone_verified_at,
      emailVerifiedAt: props.userData.email_verified_at,
      roleId: props.userData.type,
      password: "",
      phone: props.userData.phone,
      birthday: props.userData.dob, //formatRemoveTime(props.userData.dob),
      levelId: props.userData.level_id,
      stat: props.userData.stat,
      userId: props.userData.id,
      lang: props.userData.lang || 1,
      country: 1,
      referralName: props.userData.referral ? props.userData.referral.name : "",
      affId: props.userData.agency?.code || "",
      affName: props.userData.agency?.username || "",
      currency: props.userData.currency,
      note: "",
      auto_withdrawal: props.userData.auto_withdrawal == 2,
    };
    const uData = ref(JSON.parse(JSON.stringify(blankUserData)));
    // const resetuserData = () => {
    // 	userData.value = JSON.parse(JSON.stringify(blankUserData))
    // }

    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);

    const statusOptions = [
      { label: "Locked", value: 3 },
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];
    const resolveEmailOption = (type) => {
      if (type === 0) return { label: "Chưa xác thực", variant: "primary" };
      if (type === 1) return { label: "Đã xác thực", variant: "primary" };
      return { label: "None", variant: "secondary" };
    };
    const resolvePhoneOption = (type) => {
      if (type === 1) return { label: "Chưa xác thực", variant: "primary" };
      if (type === 2) return { label: "Đã xác thực", variant: "primary" };
      return { label: "None", variant: "secondary" };
    };

    const emailOptions = [
      { label: "Chưa xác thực", value: 0 },
      { label: "Đã xác thực", value: 1 },
    ];
    const phoneOptions = [
      { label: "Chưa xác thực", value: 1 },
      { label: "Đã xác thực", value: 2 },
    ];

    const languages = [
      { label: "Tiếng Việt", value: 1 },
      { label: "Tiếng Anh", value: 2 },
    ];

    const countries = [
      { label: "Việt Nam", value: 1 },
      { label: "Anh", value: 2 },
      { label: "China", value: 3 },
      { label: "Thái", value: 4 },
      { label: "Hàn", value: 5 },
      { label: "Campuchia", value: 6 },
      { label: "Lào", value: 7 },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64;
      }
    );

    const saveUser = async () => {
      try {
        return await store
          .dispatch("app-user/updateAdvanceUser", {
            userId: uData.value.userId,
            affiliate_id: uData.value.affId,
            email_verified: uData.value.emailVerify,
            phone_verified: uData.value.phoneVerify,
          })
          .then((response) => {
            if (response.data.code === "00") {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch((error) => {
            const { response } = error;
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Error Update User",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } catch (error) {}
    };

    return {
      // resolveUserRoleVariant,
      avatarText,
      statusOptions,
      permissionsData,
      languages,
      countries,

      formatRemoveTime,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      saveUser,
      uData,
      emailOptions,
      phoneOptions,
      resolveEmailOption,
      resolvePhoneOption,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<template>
  <div>
    <user-detail-tab-account-statement-total
      :user-currency="userData.currency"
    />
    <!-- <user-detail-tab-account-statement-history /> -->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import UserDetailTabAccountStatementTotal from "./account-statement/UserDetailTabAccountStatementTotal.vue";
// import UserDetailTabAccountStatementHistory from './account-statement/UserDetailTabAccountStatementHistory.vue'
import store from "@/store";

export default {
  components: {
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    UserDetailTabAccountStatementTotal,
    // UserDetailTabAccountStatementHistory,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Filters -->
    <cash-logs-list-filters
      :id-filter.sync="idFilter"
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :balance-type="balanceType"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      @refetch-data="refetchData"
    />

    <b-card v-if="fetchCashLogs" no-body class="border">
      <b-table
        ref="refCashLogsListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchCashLogs"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
      >
        <div slot="table-busy" class="text-center mt-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(transactionId)="data">
          {{ data.item.ref }}
        </template>

        <template #cell(transactionSource)="data">
          {{ data.item.balance_type.name_en }}
        </template>

        <template #cell(nameType)="data">
          <div v-if="data.item.txn_type">
            {{ data.item.txn_type.name }}
          </div>
        </template>

        <template #cell(closeBalance)="data">
          {{ parseFloat(data.item.close_balance / 1000) == 0 ? 0 : formatMoney(data.item.close_balance) }}
        </template>

        <template #cell(openBalance)="data">
          {{ parseFloat(data.item.open_balance / 1000) == 0 ? 0 : formatMoney(data.item.open_balance) }}
        </template>

        <template #cell(netAmount)="data">
          {{ parseFloat(data.item.net_amount / 1000) == 0 ? 0 : formatMoney(data.item.net_amount) }}
        </template>
        <template #cell(amount)="data">
          {{ parseFloat(data.item.amount / 1000) == 0 ? 0 : formatMoney(data.item.amount) }}
        </template>
        <template #cell(fee)="data">
          {{ parseFloat(data.item.fee / 1000) == 0 ? 0 : formatMoney(data.item.fee) }}
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>

          <b-col cols="12" sm="3"
                 class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Từ {{ dataMeta.from }} Đến {{ dataMeta.to }} Của {{ dataMeta.of }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="9"
                 class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <label class="text-muted mr-1">{{ $t("Rows per page")}}: </label>
            <b-form-group
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="mr-1"
                style="margin-top: 3px"
            >
              <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="perPageOptions"
              />
            </b-form-group>
            <b-pagination v-model="currentPage" :total-rows="totalCashLogs" :per-page="perPage" first-number
                          last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTh,
  BLink,
  BBadge,
  BPagination,
  BSpinner, BFormSelect
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import store from "@/store";
import router from "@/router";
import cashLogStoreModule from "./cash-log/cashLogStoreModule";
import CashLogsListFilters from "./cash-log/CashLogsListFilters.vue";
import i18n from "@/libs/i18n";

export default {
  components: {
    BFormSelect,
    CashLogsListFilters,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  setup() {
    const perPage = ref(25);
    const totalCashLogs = ref("");
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const idFilter = ref(null);
    const referenceFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const balanceType = ref([]);
    const balanceTypeFromFilter = ref(null);

    const refCashLogsListTable = ref(null);
    const toast = useToast();
    const loading = ref(false);

    const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";

    // Register module
    if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
      store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
    });

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "id", label: "ID giao dịch" },
      { key: "transactionId", label: "Transaction id" },
      { key: "transactionSource", label: "Transaction Source" },
      { key: "nameType", label: "Name Type" },
      { key: "openBalance", label: "Open Balance" },
      { key: "amount", label: "Amount" },
      { key: "netAmount", label: "Net Amount" },
      { key: "fee", label: i18n.t("Fee") },
      { key: "closeBalance", label: "Close Balance" },

      // { key: 'updatedBy', label: i18n.t('Updated by') },
      { key: "createdAt", label: "Created at" },
    ];

    const refetchData = () => {
      refCashLogsListTable.value.refresh();
    };

    watch(
      [
        currentPage,
        perPage,
        // referenceFilter,
        // fromdateFilter,
        // todateFilter,
        // balanceTypeFromFilter,
      ],
      () => {
        refetchData();
      }
    );
    store.dispatch("app-user/fetchUserBalanceType").then((response) => {
      balanceType.value = response.data.data;
    });

    const fetchCashLogs = (ctx, callback) => {
      loading.value = true;
      store
        .dispatch("app-user-cash-log/fetchCashLogs", {
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          userId: router.currentRoute.params.id,
          reference: referenceFilter.value,
          fromdate: fromdateFilter.value,
          balanceType: balanceTypeFromFilter.value,
          todate: todateFilter.value,
          id: idFilter.value
        })
        .then((response) => {
          const { cashlogs, total, pageSize } = response;
          callback(cashlogs);
          totalCashLogs.value = total;
          // perPage.value = pageSize;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refCashLogsListTable.value
        ? refCashLogsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCashLogs.value,
      };
    });

    const formatMoney = (value) => {
      let numericValue = parseFloat(value); // Convert string to number
      let dividedValue = numericValue / 1000; // Divide by 1000

      return dividedValue.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return {
      fetchCashLogs,
      loading,
      refetchData,
      tableColumns,
      perPage,
      currentPage,
      totalCashLogs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refCashLogsListTable,

      numberFormat,
      formatMoney,
      formatDateTime,
      idFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      balanceType,
      balanceTypeFromFilter,
    };
  },
};
</script>

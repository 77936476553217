<template>
  <div v-if="$can('view', 'userdetail')">
    <!-- User Info: Input Fields -->
    <h3 class="mb-3">{{ $t("Basic settings") }}</h3>

    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Username')" label-for="username">
            <b-form-input id="username" v-model="uData.username" disabled />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Full Name')" label-for="full-name">
            <b-form-input id="full-name" v-model="uData.fullName" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="uData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Phone -->
        <b-col cols="12" md="6" hidden>
          <b-form-group :label="$t('Phone')" label-for="phone">
            <b-form-input id="phone" v-model="uData.phone" type="text" />
          </b-form-group>
        </b-col>

        <!-- Field: Day Of Birthday -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Birthday')" label-for="dob">
            <flat-pickr
              id="dob"
              v-model="uData.birthday"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col cols="12" md="6" hidden>
          <b-form-group :label="$t('Password')" label-for="password">
            <b-form-input
              id="password"
              v-model="uData.password"
              type="password"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="6" hidden>
          <b-form-group :label="$t('Status')" label-for="user-status">
            <v-select
              v-model="uData.stat"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="true"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Country -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Country')" label-for="country">
            <v-select
              v-model="uData.country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countries"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="country"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <!-- <b-col cols="12" md="6">
          <b-form-group :label="$t('Language')" label-for="language">
            <v-select
              v-model="uData.lang"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Level -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Level')" label-for="user-level">
            <v-select
              v-model="uData.levelId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="levelLists"
              :reduce="(val) => val.id"
              label="name"
              :clearable="false"
              input-id="user-level"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Người giới thiệu')"
            label-for="referralName"
          >
            <b-form-input
              id="referralName"
              v-model="uData.referralName"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Code người giới thiệu')"
            label-for="referralCode"
          >
            <b-form-input
              id="referralCode"
              v-model="uData.referralCode"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Mã giới thiệu')" label-for="affiliate">
            <b-form-input
              id="affiliate"
              v-model="uData.my_referral"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Link giới thiệu')"
            label-for="affiliateLink"
          >
            <b-form-input
              id="affiliateLink"
              v-model="uData.my_referral_link"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- Field: Affiliate Id -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Code đại lý')" label-for="affiliateId">
            <b-form-input
              id="affiliateId"
              v-model="uData.affId"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- Field: Affiliate -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Đại lý')" label-for="affName">
            <b-form-input
              id="affName"
              v-model="uData.affName"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group :label="$t('Email xác thực')" label-for="emailVerify">
            <b-form-input
              id="emailVerify"
              v-model="uData.emailVerify"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Phone xác thực')"
            label-for="phone_verified"
          >
            <b-form-input
              id="phone_verified"
              v-model="uData.phoneVerify"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Ngày xác thực Email')"
            label-for="emailVerifiedAt"
          >
            <b-form-input
              id="emailVerifiedAt"
              v-model="uData.emailVerifiedAt"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Ngày xác thực Phone')"
            label-for="phoneVerifiedAt"
          >
            <b-form-input
              id="phoneVerifiedAt"
              v-model="uData.phoneVerifiedAt"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- Auto Withdrawal -->
        <b-col cols="12">
          <b-form-group :label="$t('Auto withdrawal')">
            <b-form-checkbox
              v-model="uData.auto_withdrawal"
              :checked="uData.auto_withdrawal"
              class="custom-control-primary"
              name="check-button"
              switch
              inline
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-button
      v-if="$can('edit', 'user')"
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 float-right"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="saveUser"
    >
      {{ $t("Save change") }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText, formatRemoveTime } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import useUsersList from "../users-list/useUsersList";
import store from "@/store";
import userStoreModule from "../userStoreModule";

export default
{
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      // required: true,
      default: null,
    },
    levelLists: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const toast = useToast();
    // const { resolveUserRoleVariant } = useUsersList();

    const blankUserData = {
      fullName: props.userData.name,
      username: props.userData.username,
      email: props.userData.email,
      emailVerify:
        props.userData.email_verified == 1 ? "Đã xác thực" : " Chưa xác thực",
      phoneVerify:
        props.userData.phone_verified == 2 ? "Đã xác thực" : " Chưa xác thực",
      my_referral: props.userData.my_referral,
      my_referral_link: props.userData.my_referral_link,
      referralCode: props.userData.referral_code,
      phoneVerifiedAt: props.userData.phone_verified_at,
      emailVerifiedAt: props.userData.email_verified_at,
      // roleId: props.userData.type,
      password: "",
      phone: props.userData.phone,
      birthday: props.userData.dob, //formatRemoveTime(props.userData.dob),
      levelId: props.userData.level_id,
      stat: "",
      userId: props.userData.id,
      lang: props.userData.lang || 1,
      country: 1,
      referralName: props.userData.referral ? props.userData.referral.name : "",
      affId: props.userData.agency?.code || "",
      affName: props.userData.agency?.username || "",
      currency: props.userData.currency,
      note: "",
      auto_withdrawal: props.userData.auto_withdrawal == 2,
    };
    const uData = ref(JSON.parse(JSON.stringify(blankUserData)));
    // const resetuserData = () => {
    // 	userData.value = JSON.parse(JSON.stringify(blankUserData))
    // }

    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);

    const statusOptions = [
      { label: "Locked", value: 3 },
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const languages = [
      { label: "Tiếng Việt", value: 1 },
      { label: "Tiếng Anh", value: 2 },
    ];

    const countries = [
      { label: "Việt Nam", value: 1 },
      { label: "Anh", value: 2 },
      { label: "China", value: 3 },
      { label: "Thái", value: 4 },
      { label: "Hàn", value: 5 },
      { label: "Campuchia", value: 6 },
      { label: "Lào", value: 7 },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64;
      }
    );

    const saveUser = async () => {
      try {
        return await store
          .dispatch("app-user/updateUser", uData.value)
          .then((response) => {
            if (response.data.code === "00") {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch((error) => {
            const { response } = error;
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Error Update User",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } catch (error) {}
    };

    return {
      // resolveUserRoleVariant,
      avatarText,
      statusOptions,
      permissionsData,
      languages,
      countries,

      formatRemoveTime,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      saveUser,
      uData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- <adjustment-list-add-new
      v-if="balanceType"
      :is-add-new-adjustment-sidebar-active.sync="
        isAddNewAdjustmentSidebarActive
      "
      :type-options="typeOptions"
      :balance-type="balanceType"
      @refetch-data="refetchData"
    /> -->
    <!-- Filters -->
    <!-- <adjustments-list-filters
      v-if="balanceType"
      :username-filter.sync="usernameFilter"
      :email-filter.sync="emailFilter"
      :phone-filter.sync="phoneFilter"
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :type-filter.sync="typeFilter"
      :balance-filter.sync="balanceFilter"
      :balance-type.sync="balanceType"
      :type-options="typeOptions"
      :status-options="statusOptions"
      :status-filter.sync="statusFilter"
    /> -->
    <b-card no-body class="mb-0">
      <!-- <div class="m-2">
        <b-row>
          <b-col v-if="$can('create', 'adjustbalance')" cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewAdjustmentSidebarActive = true"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusCircleIcon" />
                  {{ $t("Create") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div> -->

      <b-table
        ref="refLoginHistoryListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="loginHistory"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(agent)="data">
          <div style="max-width: 600px">
            <b-link style="white-space: pre-line">
              {{ data.item.agent }}
            </b-link>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(browser)="data">
          {{ data.item.browser }}
        </template>

        <template #cell(device_model)="data">
          {{ data.item.device_model }}
        </template>

        <template #cell(ip)="data">
          {{ data.item.ip }}
        </template>

        <template #cell(amount)="data">
          <div v-if="data.item.amount">
            {{ numberFormat(parseFloat(data.item.amount) / 1000) }}
          </div>
          <div v-else>0</div>
        </template>

        <template #cell(location)="data">
          {{ data.item.location }}
        </template>

        <template #cell(platform)="data">
          {{ data.item.platform }}
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdjustments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {
  avatarText,
  numberFormat,
  formatDateTime,
  resolveCurrency,
} from "@core/utils/filter";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import adjustmentStoreModule from "@/views/payments/adjustment/adjustmentStoreModule";
// import AdjustmentsListFilters from "./adjustment/AdjustmentsListFilters.vue";
import useAdjustmentsList from "@/views/apps/user/users-detail/adjustment/useAdjustmentsList";
// import AdjustmentListAddNew from "./adjustment/AdjustmentListAddNew.vue";
import userStoreModule from "../userStoreModule";
import i18n from "@/libs/i18n";
import {computed} from "@vue/composition-api/dist/vue-composition-api";

export default {
  components: {
    // AdjustmentsListFilters,
    // AdjustmentListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: [],
    }
  },
  setup(props) {
    const balanceType = ref([]);
    const refLoginHistoryListTable = ref(null);
    const totalAdjustments = ref(0);
    const currentPage = ref(1);

    // const statusOptions = ref([]);

    const isAddNewAdjustmentSidebarActive = ref(false);

    const resolveUserBalanceType = (userBalanceType, balanceTypeId) => {
      const val = userBalanceType.find((e) => e.id === balanceTypeId);
      return val.name;
    };

    const PAYMENT_ADJUSTMENT_STORE_MODULE_NAME = "payment-adjustment";

    // Register module
    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);
    if (!store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_ADJUSTMENT_STORE_MODULE_NAME,
        adjustmentStoreModule
      );

    // UnRegister on leave
    // onUnmounted(() => {
    // //   if (store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
    // //     store.unregisterModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME);
    // });

    watch([currentPage], () => {
      fetchAdjustments();
    });

    store.dispatch("app-user/fetchUserAdjustmentType").then((response) => {
      balanceType.value = response.data.data;
    });
    // store
    //   .dispatch("app-user/fetchUserAdjustmentStatusOptions")
    //   .then((response) => {
    //     statusOptions.value = response.data.data.map((val) => {
    //       return {
    //         label: val.name,
    //         value: val.id,
    //       };
    //     });
    //   });
    const loginHistory = ref();

    const fetchAdjustments = (ctx, callback) => {
      store
        .dispatch("payment-adjustment/fetchUserLogin", {
          page: currentPage.value,
          userId: props.userData.id,
        })
        .then((response) => {
          loginHistory.value = response.data.data;
          totalAdjustments.value = response.data.count;
        })
        .catch(() => {
        });
    };
    const typeOptions = [
      { label: "Plus", value: "1" },
      { label: "Minus", value: "2" },
    ];
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "agent", label: i18n.t("Agent") },
      { key: "browser", label: i18n.t("Browser") },
      { key: "device_model", label: i18n.t("Device Model") },
      // { key: "currency", label: i18n.t("Currency") },
      { key: "ip", label: i18n.t("ip") },
      // { key: "amount", label: i18n.t("Amount") },
      // { key: 'batchAdjustmentName', label: 'Batch Adjustment Name' },
      { key: "location", label: i18n.t("Location") },
      { key: "platform", label: i18n.t("Platform") },
      { key: "createdAt", label: "Created at" },
    ];

    const dataMeta = computed(() => {
      const localItemsCount = refLoginHistoryListTable.value
          ? refLoginHistoryListTable.value.localItems.length
          : 0;
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalAdjustments.value,
      };
    });

    const {
      perPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // UI
      resolveAdjustmentTypeTransactionVariant,
      resolveAdjustmentTypeTransactionLabel,
      resolveStatus,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      typeFilter,
      balanceFilter,
      statusFilter,
    } = useAdjustmentsList();
    fetchAdjustments();

    return {
      // Sidebar
      isAddNewAdjustmentSidebarActive,

      fetchAdjustments,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLoginHistoryListTable,
      // refetchData,
      resolveUserBalanceType,

      // Filter
      avatarText,
      // numberFormat,
      formatDateTime,
      resolveCurrency,

      // UI
      resolveAdjustmentTypeTransactionVariant,
      resolveAdjustmentTypeTransactionLabel,
      resolveStatus,

      typeOptions,
      balanceType,
      // statusOptions,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      typeFilter,
      balanceFilter,
      statusFilter,
      loginHistory,
      numberFormat,
    };
  },
};
</script>
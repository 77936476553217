<template>
  <div>
    <b-card no-body class="border">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('From')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="fromDate"
                class="form-control"
                placeholder="YYYY-MM-DD HH:II:SS"
                :config="flatpickrConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('To')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="toDate"
                class="form-control"
                placeholder="YYYY-MM-DD HH:II:SS"
                :config="flatpickrConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="d-flex align-items-center flex-row-reverse">
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body class="border">
      <div style="padding: 10px 30px 30px; color: #d0d2d6; font-size: 16px">
        Win: {{ moneyWin }}
      </div></b-card
    >

    <div style="padding: 10px 30px 30px; color: #d0d2d6; font-size: 16px">
      Summary
    </div>
    <b-card no-body class="border">
      <b-table
        ref="refAccountTransactionsSummaryListTable"
        class="position-relative table-white-space mb-0"
        :items="accountSummaryLists"
        responsive
        :fields="tableColumnsSummary"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <b-link>
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(count)="data">
          {{ data.item.count }}
        </template>
        <template #cell(success)="data">
          {{
            typeof data.item.success !== "string"
              ? formatCurrency(data.item.success)
              : 0
          }}
        </template>
      </b-table>
      <div class="p-2 hidden">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccountTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div style="padding: 10px 30px 30px; color: #d0d2d6; font-size: 16px">
      Payments
    </div>
    <b-card no-body class="border">
      <b-table
        ref="refAccountTransactionsPaymentListTable"
        class="position-relative table-white-space mb-0"
        :items="accountPaymentLists"
        responsive
        :fields="tableColumnsPayment"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <b-link>
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(count)="data">
          {{ data.item.count }}
        </template>

        <template #cell(pending)="data">
          {{ formatCurrency(data.item.pending) }}
        </template>
        <template #cell(process)="data">
          {{ formatCurrency(data.item.process) }}
        </template>
        <template #cell(success)="data">
          {{ formatCurrency(data.item.success) }}
        </template>
        <template #cell(fail)="data">
          {{ formatCurrency(data.item.fail) }}
        </template>
      </b-table>
    </b-card>
    <div style="padding: 10px 30px 30px; color: #d0d2d6; font-size: 16px">
      Game
    </div>
    <b-card v-if="fetchUserAccountStatements" no-body class="border">
      <b-table
        ref="refAccountTransactionsListTable"
        class="position-relative table-white-space mb-0"
        :items="fetchUserAccountStatements"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <b-link
            @click="
              showModalAccountStatementTotalDetail(
                data.item.type,
                data.item.name
              )
            "
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(count)="data">
          {{ data.item.count }}
        </template>

        <template #cell(amount)="data">
          {{ formatCurrency(data.item.amount) }}
        </template>
      </b-table>
      <div class="p-2 hidden">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccountTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal Account Statement Total -->
    <account-statement-total-modal
      :total="total"
      :user-currency="userCurrency"
      :from-date.sync="fromDate"
      :to-date.sync="toDate"
    />

    <!-- Modal Account Statement Total Deposit Detail -->
    <account-statement-total-deposit-modal
      :total="total"
      :from-date.sync="fromDate"
      :to-date.sync="toDate"
    />

    <!-- Modal Account Statement Total Withdrawlal Detail -->
    <account-statement-total-withdrawal-modal
      :total="total"
      :from-date.sync="fromDate"
      :to-date.sync="toDate"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { numberFormat } from "@core/utils/filter";
import store from "@/store";
import router from "@/router";
import AccountStatementTotalModal from "./AccountStatementTotalModal.vue";
import userStoreModule from "../../userStoreModule";
import i18n from "@/libs/i18n";

export default {
  components: {
    AccountStatementTotalDepositModal: () =>
      import("./AccountStatementTotalDepositModal.vue"),
    AccountStatementTotalWithdrawalModal: () =>
      import("./AccountStatementTotalWithdrawalModal.vue"),
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
    flatPickr,
    AccountStatementTotalModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userCurrency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      total: {
        type: "",
        des: "",
      },
      flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S", // Set your desired format here
        enableTime: true, // Enable time selection
        time_24hr: true, // Use 24-hour format for time
      },
    };
  },
  methods: {
    resetFilter() {
      this.fromDate = "";
      this.toDate = "";
    },
    searchFilter() {},
    showModalAccountStatementTotalDetail(type, des) {
      this.total.type = type;
      // this.total.des = des

      const title = des.split("_").join(" ").toUpperCase();
      this.total.des = title;
      /* if (des == 'deposit') {
				this.$bvModal.show('modal-account-statement-total-deposit')
			} else if(des == 'withdrawal') {
				this.$bvModal.show('modal-account-statement-total-withdrawal')
			} else {
				this.$bvModal.show('modal-account-statement-total-detail')
			} */
      this.$bvModal.show("modal-account-statement-total-detail");
    },
    formatCurrency(amount) {
      const floatNumber = parseFloat(amount) / 1000;
      return floatNumber.toLocaleString("vi-VN");
    },

    // console.log(formatCurrency(1000000) + ",00"); // "1.000.000,00"
  },

  setup() {
    const accountTransactions = [
      {
        id: "6",
        description: "Total Transfer In",
        count: "0",
        approved: "0",
        rejected: "0",
        outstanding: "0",
      },
      {
        id: "5",
        description: "Total Transfer Out",
        count: "0",
        approved: "0",
        rejected: "0",
        outstanding: "0",
      },
      {
        id: "4",
        description: "Total Deposit",
        count: "15",
        approved: "28.935",
        rejected: "0",
        outstanding: "800",
      },
      {
        id: "3",
        description: "Total Withdrawal",
        count: "0",
        approved: "0",
        rejected: "0",
        outstanding: "0",
      },
      {
        id: "2",
        description: "Total Adjustment Credit",
        count: "0",
        approved: "0",
        rejected: "0",
        outstanding: "0",
      },
      {
        id: "1",
        description: "Total Adjustment Debit",
        count: "0",
        approved: "0",
        rejected: "0",
        outstanding: "0",
      },
    ];

    const perPage = ref(25);
    const totalAccountTransactions = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref("");
    const fromDate = ref("");
    const toDate = ref("");
    const moneyWin = ref(0);
    const accountSummaryLists = ref([]);
    const accountPaymentLists = ref([]);

    const accountStatementLists = ref([]);

    const refAccountTransactionsListTable = ref(null);
    const refAccountTransactionsPaymentListTable = ref(null);
    const refAccountTransactionsSummaryListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "name", label: "Name" },
      { key: "amount", label: "Amount" },
      { key: "count", label: i18n.t("Count") },
    ];
    const tableColumnsPayment = [
      { key: "index", label: "#" },
      { key: "name", label: i18n.t("Name") },
      { key: "success", label: i18n.t("success") },
      { key: "fail", label: i18n.t("fail") },
      { key: "process", label: i18n.t("Inprocess") },
      { key: "pending", label: "Pending" },
      { key: "count", label: i18n.t("count") },
    ];
    const tableColumnsSummary = [
      { key: "index", label: "#" },
      { key: "name", label: i18n.t("Name") },
      { key: "success", label: i18n.t("Số tiền") },
      { key: "count", label: i18n.t("Count") },
    ];

    // Register module
    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule('app-user')) store.unregisterModule('app-user')
    });

    const dataMeta = computed(() => {
      const localItemsCount = refAccountTransactionsListTable.value
        ? refAccountTransactionsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalAccountTransactions.value,
      };
    });

    const refetchData = () => {
      refAccountTransactionsListTable.value.refresh();
      refAccountTransactionsPaymentListTable.value.refresh();
      refAccountTransactionsSummaryListTable.value.refresh();
    };

    watch([currentPage, fromDate, toDate], () => {
      refetchData();
    });
    const fetchUserAccountStatements = (ctx, callback) => {
      store
        .dispatch("app-user/fetchUserAccountStatement", {
          userId: router.currentRoute.params.id,
          fromDate: fromDate.value,
          toDate: toDate.value,
        })
        .then((response) => {
          accountStatementLists.value = response.data.data.game;
          accountPaymentLists.value = response.data.data.payment;
          accountSummaryLists.value = response.data.data.summary;
          moneyWin.value = numberFormat(response.data.data.win / 1000);
          callback(response.data.data.game);
          totalAccountTransactions.value = response.data.data.game.length;
        })
        .catch((error) => {
          accountStatementLists.value = undefined;
        });
    };
    // const fetchUserAccountStatementsPayment = (ctx, callback) => {
    //   store
    //     .dispatch("app-user/fetchUserAccountStatement", {
    //       userId: router.currentRoute.params.id,
    //       fromDate: fromDate.value,
    //       toDate: toDate.value,
    //     })
    //     .then((response) => {
    //       accountStatementLists.value = response.data.data.payment;

    //       callback(response.data.data.payment);
    //       console.log(12344,accountSummaryLists.value);
    //       totalAccountTransactions.value = response.data.data.payment.length;
    //     })
    //     .catch((error) => {
    //       accountStatementLists.value = undefined;
    //     });
    // };

    return {
      fetchUserAccountStatements,
      // fetchUserAccountStatementsPayment,
      refAccountTransactionsSummaryListTable,
      tableColumns,
      perPage,
      currentPage,
      totalAccountTransactions,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refAccountTransactionsListTable,
      refAccountTransactionsPaymentListTable,
      accountTransactions,
      accountStatementLists,

      numberFormat,
      fromDate,
      toDate,
      tableColumnsPayment,
      moneyWin,
      tableColumnsSummary,
      accountSummaryLists,
      accountPaymentLists,
    };
  },
};
</script>

<template>
  <div>
    <UserDetailCardTransactionListFilters
      @refetch-data="refetchData"
      :refFilter.sync="refFilter"
      :codeFilter.sync="codeFilter"
      :serialFilter.sync="serialFilter"
      :type-filter.sync="typeFilter"
      :status-filter.sync="statusFilter"
      :cardTypeOptions="cardTypeOptions"
    />
    <b-card no-body>
      <b-table
        ref="refCardTransactionListTable"
        class="position-relative table-white-space mb-0"
        :items="cardTransactionList"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(username)="data">
          <div v-if="data.item.user">
            {{ data.item.user.username }}
          </div>
        </template>
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span />{{ data.item.status }}
          </div>
        </template>

        <template #cell(amount)="data">
          {{ numberFormat(parseFloat(data.item.amount) / 1000) }}
        </template>

        <template #cell(fee)="data">
          {{ numberFormat(parseFloat(data.item.fee) / 1000) }}
        </template>

        <template #cell(ref)="data">
          {{ data.item.ref }}
        </template>
        <template #cell(code)="data">
          {{ data.item.code }}
        </template>
        <template #cell(serial)="data">
          {{ data.item.serial }}
        </template>
        <template #cell(card_type)="data">
          <div v-if="data.item.card_type">
            {{ data.item.card_type.name }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{
            formatDateTime(
              data.item.created_at ? data.item.created_at : ""
            ).replace(".000000Z", "")
          }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCardTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import UserDetailCardTransactionListFilters from "./UserDetailCardTransactionListFilters.vue";
import cardTransactionStoreModule from "@/views/payments/card-transaction/cardTransactionStoreModule";
import i18n from "@/libs/i18n";
import router from "@/router";

export default {
  components: {
    UserDetailCardTransactionListFilters,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
  },
  setup() {
    // Register module
    if (!store.hasModule("card-transaction"))
      store.registerModule("card-transaction", cardTransactionStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("card-transaction"))
        store.unregisterModule("card-transaction");
    });
    const toast = useToast();
    const perPage = ref(25);
    const totalCardTransactions = ref(0);
    const currentPage = ref(1);
    const refFilter = ref(null);
    const codeFilter = ref(null);
    const serialFilter = ref(null);
    const typeFilter = ref(null);
    const statusFilter = ref(null);
    const cardTypeOptions = ref([]);

    const cardTransactionList = ref([]);

    const refCardTransactionListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "username", label: i18n.t("Username") },
      { key: "amount", label: i18n.t("amount") },
      { key: "fee", label: i18n.t("Fee") },
      { key: "ref", label: "ref" },
      { key: "code", label: i18n.t("code") },
      { key: "serial", label: i18n.t("serial") },
      { key: "card_type", label: i18n.t("Card Type") },
      { key: "status", label: i18n.t("status") },
      { key: "createdAt", label: i18n.t("Created at") },
    ];

    store
        .dispatch("card-transaction/fetchCardTransactions", {
          page: currentPage.value,
          userId: router.currentRoute.params.id,
        })
        .then((response) => {
          if (response.data.code === "00") {
            cardTransactionList.value = response.data.data;
            totalCardTransactions.value = response.data.count;
            perPage.value =
              currentPage.value === 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });

    const fetchCardTransactions = (ctx, callback) => {
      store
        .dispatch("card-transaction/fetchCardTransactions", {
          page: currentPage.value,
          userId: router.currentRoute.params.id,
          ref: refFilter.value,
          code: codeFilter.value,
          serial: serialFilter.value,
          type: typeFilter.value,
          status: statusFilter.value,
        })
        .then((response) => {
          if (response.data.code === "00") {
            cardTransactionList.value = response.data.data;
            totalCardTransactions.value = response.data.count;
            perPage.value =
              currentPage.value === 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const refetchData = () => {
      fetchCardTransactions();
    };

    watch(
      [
        currentPage,
      ],
      () => {
        refetchData();
      }
    );

    const dataMeta = computed(() => {
      const localItemsCount = refCardTransactionListTable.value
        ? refCardTransactionListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCardTransactions.value,
      };
    });

    const resolveTransactionType = (type) => {
      if (type === 1) return i18n.t("Deposit");
      if (type === 2) return i18n.t("Withdrawal");
    };

    const transactionTypeOptions = [
      { label: i18n.t("Deposit"), value: 1 },
      { label: i18n.t("Withdrawal"), value: 2 },
    ];
    store
      .dispatch("card-transaction/fetchCardType")
      .then((response) => {
        cardTypeOptions.value = response.data.data.map((val)=>{
          return {
            label : val.name,
            value:val.code
          }
        });
      });
    const statusOptions = [
      { label: i18n.t("Active"), value: 1 },
      { label: i18n.t("Inactive"), value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status === 1) return { variant: "warning", label: "Pending" };
      if (status === 2) return { variant: "primary", label: "Inprocess" };
      if (status === 3) return { variant: "danger", label: "Fail" };
      if (status === 4) return { variant: "success", label: "Success" };
      return { label: "None", variant: "secondary" };
    };
    return {
      tableColumns,
      perPage,
      currentPage,
      totalCardTransactions,
      dataMeta,
      refCardTransactionListTable,
      refetchData,
      cardTypeOptions,
      statusFilter,
      refFilter,
      codeFilter,
      serialFilter,
      typeFilter,

      resolveStatus,
      statusOptions,

      formatDateTime,
      numberFormat,
      resolveTransactionType,
      cardTransactionList
    };
  },
};
</script>
<style>
.b-table-sticky-column {
  right: 0 !important;
}
td.b-table-sticky-column {
  background-color: inherit;
}
</style>
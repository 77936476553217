import {
  ref,
  watch,
  computed,
  onMounted,
  onUpdated,
} from "@vue/composition-api";
import { title, paginateArray, sortCompare } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import i18n from "@/libs/i18n";

export default function useDepositsList() {
  // Use toast
  const toast = useToast();

  const refDepositListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "ref", label: i18n.t("Transaction id"), stickyColumn: true },
    { key: "username", label: i18n.t("Username") },
    { key: "status", label: i18n.t("Status") },
    { key: "type", label: i18n.t("Payment type") },
    { key: "Mode", label: i18n.t("Method") },
    // { key: "currency", label: i18n.t("Currency") },
    { key: "amount", label: `${i18n.t("Amount")} Gross` },
    { key: "netAmount", label: `${i18n.t("Amount")} Net` },
    { key: "createdAt", label: i18n.t("Created at") },
    { key: "updatedAt", label: i18n.t("Updated at") },
  ];
  const perPage = ref(25);
  const totalDeposits = ref(0);
  const currentPage = ref(1);
  const totalMoney = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const userIdFilter = ref(router.currentRoute.params.id);
  const emailFilter = ref(null);
  const fullnameFilter = ref(null);
  const phoneFilter = ref(null);
  const referenceFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);

  const refetchData = () => {
    refDepositListTable.value.refresh();
  };

  watch(
    [currentPage],
    () => {
      refetchData();
    }
  );

  const fetchDeposits = (ctx, callback) => {
    store
      .dispatch("payment-deposit/fetchDeposits", {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        userId: userIdFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        stat: statusFilter.value,
      })
      .then((response) => {
        const { deposits, total, pageSize, money } = response;
        callback(deposits);
        totalDeposits.value = total;
        perPage.value = pageSize;
        totalMoney.value = money;
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refDepositListTable.value
      ? refDepositListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDeposits.value,
    };
  });

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  const resolveType = (type) => {
    if (type === 1) return "Deposit";
    if (type === 2) return "Local deposit";
    if (type === 3) return "Local transfer";
  };

  return {
    fetchDeposits,
    tableColumns,
    perPage,
    currentPage,
    totalDeposits,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refDepositListTable,

    resolveStatus,
    resolveType,
    refetchData,

    // Extra Filters
    usernameFilter,
    userIdFilter,
    emailFilter,
    fullnameFilter,
    phoneFilter,
    referenceFilter,
    fromdateFilter,
    todateFilter,
    statusFilter,
    totalMoney,
  };
}

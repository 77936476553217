import { ref, watch, computed, onMounted } from "@vue/composition-api";
import { title, paginateArray, sortCompare } from "@core/utils/filter";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import i18n from "@/libs/i18n";

export default function useAdjustmentsList() {
  // Use toast
  const toast = useToast();

  const refAdjustmentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "reference", label: i18n.t("Transaction id") },
    { key: "username", label: i18n.t("Username") },
    { key: "status", label: i18n.t("Status") },
    // { key: "agent", label: i18n.t("Agent") },
    // { key: "browser", label: i18n.t("Browser") },
    // { key: "device_model", label: i18n.t("Device Model") },
    { key: "type", label: i18n.t("Transaction type") },
    // { key: "currency", label: i18n.t("Currency") },
    // { key: "ip", label: i18n.t("ip") },
    { key: "amount", label: i18n.t("Amount") },
    // { key: 'batchAdjustmentName', label: 'Batch Adjustment Name' },
    { key: "note", label: i18n.t("Note") },
    // { key: "location", label: i18n.t("Location") },
    // { key: "platform", label: i18n.t("Platform") },
    { key: "createdAt", label: i18n.t("Created at") },
    { key: "updatedAt", label: i18n.t("Updated at") },
  ];
  const perPage = ref(25);
  const totalAdjustments = ref(0);
  const currentPage = ref(1);
  const totalMoney = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const userIdFilter = ref(router.currentRoute.params.id);
  const emailFilter = ref(null);
  const phoneFilter = ref(null);
  const referenceFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);
  const balanceFilter = ref(null);
  const typeFilter = ref(null);

  const refetchData = () => {
    refAdjustmentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      // perPage,
      searchQuery,
      usernameFilter,
      phoneFilter,
      emailFilter,
      userIdFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      balanceFilter,
      typeFilter,
      statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchAdjustments = (ctx, callback) => {
    store
      .dispatch("payment-adjustment/fetchAdjustments", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        phone: phoneFilter.value,
        userId: userIdFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        balanceId: balanceFilter.value,
        type: typeFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { adjustments, total, pageSize, money } = response;
        callback(adjustments);
        totalAdjustments.value = total;
        perPage.value = pageSize;
        totalMoney.value = money;
      })
      .catch(() => {
        /* toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching adjustments list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				}) */
      });
  };

  const AdjustmentReasonAPI = (ctx, callback) => {
    store
      .dispatch("reason/fetchAdjustmentReason", {
        id: idFilter.value,
      })
      .then((response) => {
        callback(response.data.data);
      })
      .catch((error) => {
        const { response } = error;
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || "Error fetching transfers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refAdjustmentListTable.value
      ? refAdjustmentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdjustments.value,
    };
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAdjustmentTypeTransactionVariant = (type) => {
    if (type === 1) return "success";
    if (type === 2) return "warning";
    return "secondary";
  };

  const resolveAdjustmentTypeTransactionLabel = (type) => {
    if (type === 1) return "Credit";
    if (type === 2) return "Debit";
    return "None";
  };

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  return {
    fetchAdjustments,
    tableColumns,
    perPage,
    currentPage,
    totalAdjustments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdjustmentListTable,

    resolveAdjustmentTypeTransactionVariant,
    resolveAdjustmentTypeTransactionLabel,
    resolveStatus,
    refetchData,
    AdjustmentReasonAPI,

    // Extra Filters
    usernameFilter,
    userIdFilter,
    emailFilter,
    phoneFilter,
    referenceFilter,
    fromdateFilter,
    todateFilter,
    statusFilter,
    balanceFilter,
    typeFilter,
    totalMoney,
  };
}

/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <transfers-list-filters
      v-if="balanceType"
      :username-filter.sync="usernameFilter"
      :email-filter.sync="emailFilter"
      :phone-filter.sync="phoneFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :reference-filter.sync="referenceFilter"
      :status-filter.sync="statusFilter"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      :balance-type-to-filter.sync="balanceTypeToFilter"
      :created-by-filter.sync="createdByFilter"
      :status-options="statusOptions"
      :balance-type="balanceType"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col v-if="$can('create', 'transfer')" cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showModalAddTransfer()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusCircleIcon" />
                  {{ $t("Create") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTransferListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchTransfers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(reference)="data">
          <b-link>
            {{ data.item.ref }}
          </b-link>
        </template>

        <template #cell(partnerReference)="data"> - </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          {{ data.item.user.username }}
        </template>

        <template #cell(currency)="data">
          {{ userData.currency }}
        </template>

        <template #cell(fromto)="data">
          {{ data.item.balance_type_from.name_en }} /
          {{ data.item.balance_type_to.name_en }}
        </template>

        <template #cell(bonus_code)="data"
          ><div v-if="data.item.bonus_code">
            {{ data.item.bonus_code.name }}
          </div></template
        >

        <template #cell(bonusAmount)="data"> <span class="text-primary">{{
            numberFormat(parseFloat(data.item.amount_bonus) / 1000)
          }}</span></template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(parseFloat(data.item.amount) / 1000)
          }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />{{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              variant="gradient-primary"
              class="btn-icon btn-sm rounded-circle"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransfers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <user-detail-add-transfer-modal
      :user-data="userData"
      :balances-list="balancesList"
      :balance-type="balanceType"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatDateTime,
  resolveCurrency,
} from "@core/utils/filter";
import store from "@/store";
import transferStoreModule from "@/views/payments/transfer/transferStoreModule";
import userStoreModule from "@/views/apps/user/userStoreModule";
import TransfersListFilters from "./transfer/TransfersListFilters.vue";
import useTransfersList from "./transfer/useTransfersList";
// import UserDetailAddTransferModal from './transfer/UserDetailAddTransferModal.vue'
import router from "@/router";

export default {
  components: {
    TransfersListFilters,
    UserDetailAddTransferModal: () =>
      import("./transfer/UserDetailAddTransferModal.vue"),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    balancesList: {
      type: Array,
      required: [],
    },
    statusOptions: {
      type: Array,
      required: [],
    }
  },
  // data() {
  //   return {
  //     balanceType: []
  //   }
  // },
  methods: {
    async showModalAddTransfer() {
      this.$bvModal.show("modal-add-transfer");
    },
  },
  setup() {
    const balanceType = ref([]);
    const PAYMENT_TRANSFER_STORE_MODULE_NAME = "payment-transfer";
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(PAYMENT_TRANSFER_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_TRANSFER_STORE_MODULE_NAME,
        transferStoreModule
      );
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_TRANSFER_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_TRANSFER_STORE_MODULE_NAME);
      // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    });

    store.dispatch("app-user/fetchUserBalanceType").then((response) => {
      balanceType.value = response.data.data;
    });

    const {
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,

      // UI
      resolveStatus,

      // Extra Filters
      usernameFilter,
      emailFilter,
      phoneFilter,
      fromdateFilter,
      todateFilter,
      referenceFilter,
      statusFilter,
      balanceTypeFromFilter,
      balanceTypeToFilter,
      createdByFilter,
    } = useTransfersList();

    return {
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      // UI
      resolveStatus,

      // Extra Filters
      usernameFilter,
      emailFilter,
      phoneFilter,
      fromdateFilter,
      todateFilter,
      referenceFilter,
      statusFilter,
      balanceTypeFromFilter,
      balanceTypeToFilter,
      createdByFilter,
      balanceType
    };
  },
};
</script>
/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";
import { paginateArray, sortCompare } from "@core/utils/filter";

class BankAccountService {
  getBankAccountLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      bankAccount: queryParams.bankAccount,
      bankId: queryParams.bankId,
      provinceId: queryParams.provinceId,
      phone: queryParams.phone,
      stat: queryParams.stat,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/UserBankAccount/index", {
        headers: authHeader(),
        params: pars,
      })
      .then((response) => {
        const datas = {
          bankAccounts: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  viewBankAccount(id) {
    return instance.get("/api/UserBankAccount/view-bank-account", {
      headers: authHeader(),
      params: { userBankAccountId: id },
    });
  }
  getBalanceAccountLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      bankAccount: queryParams.bankAccount,
      bankId: queryParams.bankId,
      provinceId: queryParams.provinceId,
      phone: queryParams.phone,
      stat: queryParams.stat,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/User/balance", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          balanceAccounts: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  updateBankAccount(bankData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', bankData.id)
		data.append('bank_id', bankData.bank_id)
		data.append('bank_account', bankData.bank_account)
		data.append('stat', bankData.status)

		return instance.post('/api/UserBankAccount/update', data, { headers: authHeader() })
    }
}

export default new BankAccountService();

<template>
	<div>
		<!-- User Info: Input Fields -->
		<h3 class="mb-3">{{ $t('Security settings') }}</h3>

		<b-row>
			<!-- Field: Password -->
			<b-col
				cols="12"
				class="pb-2"
			>
				<b-row>
					<b-col cols="12">
						<h5>{{ $t('Password') }}</h5>
					</b-col>
					<b-col>
						********
					</b-col>
					<b-col class="text-right">
						<b-link
							v-if="$can('change','userpassword')"
							@click="showModalUpdatePassword()"
						>
							{{ $t('Modify') }}
						</b-link>
					</b-col>
				</b-row>
			</b-col>

			<!-- Field: Email -->
			<b-col
				cols="12"
				class="pb-2"
			>
				<b-row>
					<b-col cols="12">
						<h5>Email</h5>
					</b-col>
					<b-col>
            {{ hiddenEmail(updateEmail === 'none' ? userData.email : updateEmail) }}
            <span v-if="userData.email_verified === 0">
							<feather-icon
								icon="AlertTriangleIcon"
								size="16"
								class="align-middle text-danger"
							/>
						</span>
						<span v-else>
							<feather-icon
								icon="CheckCircleIcon"
								size="16"
								class="align-middle text-success"
							/>
						</span>
					</b-col>
					<b-col class="text-right">
						<b-link
							v-if="$can('edit','user')"
							@click="showModalUpdateEmail()"
						>
							{{ $t('Modify') }}
						</b-link>
					</b-col>
				</b-row>
			</b-col>

			<!-- Field: Phone -->
			<b-col
				cols="12"
				class="pb-2"
			>
				<b-row>
					<b-col cols="12">
						<h5>{{ $t('Phone') }}</h5>
					</b-col>
					<b-col>
						{{ updatePhone == 'none' ? userData.phone.replace(userData.phone.substr(-7, 7),"*******") : updatePhone.replace(updatePhone.substr(-7, 7),"*******") }}
						<span>
							<feather-icon
								icon="AlertTriangleIcon"
								size="16"
								class="align-middle text-danger"
							/>
						</span>
					</b-col>
					<b-col class="text-right">
						<b-link
							v-if="$can('edit','user')"
							@click="showModalUpdatePhone()"
						>
							{{ $t('Modify') }}
						</b-link>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<!-- Modal Update Password-->
		<user-detail-change-password-modal
			:user-data="userData"
		/>
		<!-- Modal Update Email-->
		<user-detail-change-email-modal
			:user-data="userData"
			:update-email.sync="updateEmail"
		/>

		<!-- Modal Update Phone-->
		<user-detail-change-phone-modal
			:user-data="userData"
			:update-phone.sync="updatePhone"
		/>
	</div>
</template>

<script>
import {
	BLink, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { hiddenEmail } from '@core/utils/filter'
import UserDetailChangePasswordModal from './UserDetailChangePasswordModal.vue'
import UserDetailChangeEmailModal from './UserDetailChangeEmailModal.vue'
import UserDetailChangePhoneModal from './UserDetailChangePhoneModal.vue'

export default {
	components: {
		BLink, BRow, BCol, BButton,
		UserDetailChangePasswordModal,
		UserDetailChangeEmailModal,
		UserDetailChangePhoneModal,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			updateEmail: 'none',
			updatePhone: 'none',
		}
	},
	computed: {
	},
	methods: {
		showModalUpdatePassword() {
			this.$bvModal.show('modal-update-password')
		},
		showModalUpdateEmail() {
			this.$bvModal.show('modal-update-email')
		},
		showModalUpdatePhone() {
			this.$bvModal.show('modal-update-phone')
		},
	},
	setup(props) {
    console.log('dt', props.userData)
		return {
			hiddenEmail,
		}
	},
}
</script>

<style>

</style>

import CashLogService from '@/libs/cash-log.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCashLogs(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CashLogService.getCashLogLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchTxnType(ctx) {
			return new Promise((resolve, reject) => {
				CashLogService.getTxnType()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchTotalOutStandingBalance(ctx, id) {
			return new Promise((resolve, reject) => {
				CashLogService.getTotalOutStandingBalance(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchOutStandingBalance(ctx, id) {
			return new Promise((resolve, reject) => {
				CashLogService.getOutStandingBalance(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchBalanceAccount(ctx, id) {
			return new Promise((resolve, reject) => {
				CashLogService.getBalanceAccount(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchBetHistory(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CashLogService.getBetHistory(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchCallHistory(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CashLogService.getCallHistory(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchTitleMessage(ctx, id) {
			return new Promise((resolve, reject) => {
				CashLogService.getTitleMessage(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchMessageHistory(ctx, id) {
			return new Promise((resolve, reject) => {
				CashLogService.getMessageHistory(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		
		

    }
}

import LocalDepositService from '@/libs/local-deposit.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchLocalDeposits(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				LocalDepositService.getLocalDepositLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addLocalDeposit(ctx, localDepositData) {
			return new Promise((resolve, reject) => {
				LocalDepositService.addLocalDeposit(localDepositData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateLocalDepositStatus(ctx, localDepositStatus) {
			return new Promise((resolve, reject) => {
				LocalDepositService.updateLocalDepositStatus(localDepositStatus)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    }
}
